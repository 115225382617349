import { Stack } from '@mui/material';
import { DatePicker, Select, SelectData, TextField } from 'mui-rff';
import { useTranslation } from 'react-i18next';
import { required } from '../../../../../utils/validation';
import { ChangeEvent, useState } from 'react';
import { useForm } from 'react-final-form';

const professional_status = [
  {
    value: 'Employed',
    label: 'Employed',
  },
  {
    value: 'Unemployed',
    label: 'Unemployed',
  },
  {
    value: 'Retired',
    label: 'Retired',
  },
  {
    value: 'Self-employed',
    label: 'Self-employed',
  },
  {
    value: 'Student',
    label: 'Student',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const Occupation = () => {
  const form = useForm();

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLButtonElement;
    if (!['Other', 'Self-employed'].includes(target?.value)) {
      form.change('data.custom_message', '');
    }
  };

  const { t } = useTranslation('onBoarding', {
    keyPrefix: 'Occupation.fields',
  });

  return (
    <Stack spacing={2} width='100%'>
      <TextField name='data.occupation' label={t('occupation')} />
      <Select
        name='data.professional_status'
        label={t('professional_status')}
        data={professional_status}
        required
        fieldProps={{ validate: required }}
        inputProps={{ onChange: handleStatusChange }}
      />
      {['Other', 'Self-employed'].includes(
        form.getState().values.data?.professional_status
      ) && (
        <TextField
          name='data.custom_message'
          label={t('custom_message')}
          required
          fieldProps={{ validate: required }}
        />
      )}
      <TextField name='data.company_name' label={t('company_name')} />
      <TextField name='data.industry' label={t('industry')} />
      {/*
        для задачи OB-459
        <TextField name='data.education' label={t('education')} />
      */}
    </Stack>
  );
};
