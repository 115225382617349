import { FC, useState, useCallback, useMemo, useEffect } from 'react';
import { Box, IconButton, Modal, Stack } from '@mui/material';
import {
  FailurePhoto,
  SuccessPhoto,
  WidgetLogo,
} from '../../../../assets/icons';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  CameraSelfieWrapper,
  LabelInput,
  ErrorLabel,
  ModalWrap,
  Logo,
} from './styles';
import { FaceRecognition } from '../../../cameraRecognition/components/FaceRecognition';
import { HowScan } from './HowScan';
import { CloseBtnModal } from '../CloseBtnModal';

const statusIcon: {
  success: string;
  error: string;
} = {
  success: SuccessPhoto,
  error: FailurePhoto,
};

const content: { [key: string]: (props?: any) => JSX.Element } = {
  info: ({ handleNextStep }) => <HowScan handleNextStep={handleNextStep} />,
  camera: ({ handleFinish, handleNextStep }) => (
    <FaceRecognition onFinish={handleFinish} handleNextStep={handleNextStep} />
  ),
};

export const CameraSelfie: FC<{
  input: any;
  meta: any;
  label: string;
  icon: string;
  width?: string;
  height?: string;
  onBlockContinue?: (value: boolean) => void;
}> = ({ input, meta, label, icon, width, height, onBlockContinue }) => {
  const [step, setStep] = useState<'camera' | 'info'>('info');
  const [open, setOpen] = useState<boolean>(false);
  const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    if (imagesLoaded) return;
    setOpen(true);
  }, [imagesLoaded]);
  const handleClose = useCallback(() => setOpen(false), []);

  const handleNextStep = useCallback((nextStep) => setStep(nextStep), []);

  const handleFinish = useCallback(
    (files: File[]) => {
      input.onChange({ inputData: files });
      handleClose();
      setImagesLoaded(true);
    },
    [input, handleClose]
  );

  const handleDelete = useCallback(() => {
    setImagesLoaded(false);
    input.onChange(null);
  }, [input]);

  const handleRetakeModal = useCallback(() => {
    if (imagesLoaded) {
      setOpen(true);
    }
    handleDelete();
  }, [imagesLoaded, handleDelete]);

  const status = input.value
    ? 'success'
    : meta?.error && meta?.touched
    ? 'error'
    : 'pending';

  const screenStep = useMemo(
    () => content[step]({ handleFinish, handleNextStep }),
    [step]
  );

  useEffect(() => {
    onBlockContinue?.(!input?.value);
  }, [input?.value, onBlockContinue]);

  const isUploaded = !!input?.value?.storedData;
  const isSelected = !!input?.value?.inputData;

  return (
    <>
      <CameraSelfieWrapper
        status={status}
        onClick={handleOpen}
        width={width}
        height={height}
      >
        <Stack
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          gap='8px'
        >
          <img
            src={status === 'pending' ? icon : statusIcon[status]}
            alt='selfie'
          />
          <LabelInput status={status}>{label}</LabelInput>
        </Stack>
        {((isUploaded && !isSelected) || input.value) && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              transform: 'translate(0,-50%)',
              right: '16px',
            }}
          >
            {
              <IconButton
                sx={{ color: '#8D959F' }}
                size='small'
                onClick={handleRetakeModal}
              >
                <RefreshTwoToneIcon />
              </IconButton>
            }
            <IconButton
              sx={{ color: '#8D959F' }}
              size='small'
              onClick={handleDelete}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </CameraSelfieWrapper>
      {meta.error && (
        <Box mt={1}>
          <ErrorLabel>{meta.touched ? meta.error : ''}</ErrorLabel>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        sx={{
          '& .MuiBackdrop-root': {
            background: '#E3E3E3',
          },
        }}
      >
        <>
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <CloseBtnModal onClick={handleClose} isLabel />
            <ModalWrap flexDirection='column' alignItems='center'>
              {screenStep}
            </ModalWrap>
          </Box>
          <Logo>
            <img src={WidgetLogo} alt='logo' />
          </Logo>
        </>
      </Modal>
    </>
  );
};
