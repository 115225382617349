import { useCallback, useContext, useState } from "react";
import { AuthContext, AuthContextValue } from "./AuthProvider";

export const useConfirmEmail = (): [
  boolean | null,
  boolean,
  Error | null,
  (phone: string, code: string, salt: string) => void
] => {
  const { confirmEmail } = useContext<AuthContextValue>(AuthContext);
  const [result, setResult] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (phone: string, code: string, salt: string) => {
      setIsLoading(true);
      setError(null);

      try {
        await confirmEmail(phone, code, salt);
        setResult(true);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error("Unknown error"));
        }
      }
      setIsLoading(false);
    },
    [confirmEmail]
  );

  return [result, isLoading, error, request];
};
