import { createTheme } from "@mui/material";

export const mainTheme = createTheme({
  palette: {
    background: {
      default: "#F4F4F4",
    },
  },
  typography: {
    fontFamily: ['"Red Hat Display"', "sans-serif"].join(","),
    h1: {
      fontWeight: "800",
      fontSize: "32px",
      lineHeight: "40px",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          textTransform: "capitalize",
          borderRadius: "12px",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "filled",
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          overflow: "hidden",
          background: "#FFF",
          borderRadius: "12px",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        variant: "filled",
      },
      styleOverrides: {
        root: {
          fontFamily: "Red Hat Display",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "24px",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "filled",
      },
      styleOverrides: {
        filled: {
          paddingTop: "17px 16px 12px",
          overflow: "hidden",
          background: "#FFF",
          borderRadius: "12px",
          fontFamily: "Red Hat Display",
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "24px",
        },
      },
    },
  },
});
