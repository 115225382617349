import { FC, memo } from 'react'
import { Box, Stack } from '@mui/material'
import { checkmark } from '../../../../../assets/icons'

export const ImageBlock: FC<{
  image: string
}> = memo(({ image }) => (
  <Stack
    flexDirection='row'
    alignItems='center'
    justifyContent='center'
    sx={{ position: 'relative', width: '100%', paddingTop: '12px' }}
  >
    <Box mr='16px' sx={{ position: 'absolute', top: '50%', left: '0' }}>
      <img src={checkmark} alt='mark' />
    </Box>
    <Box>
      <img src={image} alt='image' />
    </Box>
  </Stack>
))
