import {
  IDCardIcon,
  GovermentDocIcon,
  CarIcon,
} from '../../../components/MuiIcons'

export const documentsIcons: {
  [key: string]: any
} = {
  DriverLicence: CarIcon,
  Passport: GovermentDocIcon,
  Id: IDCardIcon,
}

export const getAllowedDocumentIcon = (
  documentType: string,
  props: any = {}
): JSX.Element => {
  if (documentType in documentsIcons) {
    const Icon = documentsIcons[documentType]
    return <Icon {...props} />
  }

  // #TODO: add default document icon
  return <IDCardIcon {...props} />
}
