import { FC } from 'react'
import { Box, Card, CardContent, Grid, Modal, Typography } from '@mui/material'
import { SelfieRequirementsWrapper } from './styles'
import { CloseBtnModal } from '../CloseBtnModal'
import { ImageBlock, TextBlock } from './UpperBlocks'
import { selfieRequire } from '../../../../assets/icons'
import { useTranslation } from 'react-i18next'

const listItems1: { id: number; text: string }[] = [
  {
    id: 1,
    text: 'no_head_gear',
  },
  {
    id: 2,
    text: 'face_is_visible',
  },
]

const listItems2: { id: number; text: string }[] = [
  {
    id: 1,
    text: 'do_not_crop',
  },
]

const listItems3: { id: number; text: string }[] = [
  {
    id: 1,
    text: 'do_not_hide_face',
  },
]

export const SelfieRequirementsModal: FC<{
  open: boolean
  handleClose: () => void
}> = ({ open, handleClose }) => {
  const { t } = useTranslation('onBoarding', {
    keyPrefix: 'SelfieRequirements',
  })
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{
        '& .MuiBackdrop-root': {
          background: '#E3E3E3',
        },
      }}
    >
      <SelfieRequirementsWrapper>
        <CloseBtnModal onClick={handleClose} top='7px' right='6px' />
        <Box>
          <Typography
            fontWeight='800'
            fontSize='24px'
            lineHeight='32px'
            textAlign='center'
            letterSpacing='0.5px'
            color='#1C1F21'
          >
            {t('image_requirements')}
          </Typography>
        </Box>
        <Grid container spacing={1.5} columns={16} sx={{ marginTop: '24px' }}>
          <Grid item xs={8}>
            <Card
              variant='outlined'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '335px',
                height: '168px',
                background: '#EEF3FE',
                borderRadius: '14px',
                border: 'none',
              }}
            >
              <CardContent
                sx={{
                  padding: '0',
                  paddingLeft: '14px',
                  '&:last-child': { paddingBottom: '12px' },
                }}
              >
                <ImageBlock image={selfieRequire} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <Card
              variant='outlined'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '335px',
                height: '168px',
                background: '#EEF3FE',
                borderRadius: '14px',
                border: 'none',
              }}
            >
              <CardContent
                sx={{
                  padding: '0',
                  paddingLeft: '16px',
                  paddingTop: '14px',
                  '&:last-child': { paddingBottom: '12px' },
                }}
              >
                <TextBlock listItems={listItems1} isCheck />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={1.5} columns={16} sx={{ marginTop: '16px' }}>
          <Grid item xs={8}>
            <Card
              variant='outlined'
              sx={{
                width: '335px',
                height: '76px',
                background: '#cf000015',
                borderRadius: '14px',
                border: 'none',
              }}
            >
              <CardContent
                sx={{
                  padding: '0',
                  paddingLeft: '16px',
                  paddingTop: '7px',
                  '&:last-child': { paddingBottom: '12px' },
                }}
              >
                <TextBlock listItems={listItems2} />
              </CardContent>
            </Card>
          </Grid>{' '}
          <Grid item xs={8}>
            <Card
              variant='outlined'
              sx={{
                width: '335px',
                height: '76px',
                background: '#cf000015',
                borderRadius: '14px',
                border: 'none',
              }}
            >
              <CardContent
                sx={{
                  padding: '0',
                  paddingLeft: '16px',
                  paddingTop: '7px',
                  '&:last-child': { paddingBottom: '12px' },
                }}
              >
                <TextBlock listItems={listItems3} />
              </CardContent>
            </Card>
          </Grid>{' '}
        </Grid>
      </SelfieRequirementsWrapper>
    </Modal>
  )
}
