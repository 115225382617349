import { useCallback, useEffect, useState } from 'react';
import { TVerificationDocument, TVerificationDocumentFull } from '../models';
import { getDocumentData } from '../network';

export const useLoadDocuments = (
  verificationRequestId: string,
  ids: string[]
): [TVerificationDocumentFull[] | null, boolean, Error | null, () => void] => {
  const [result, setResult] = useState<TVerificationDocumentFull[] | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async () => {
    if (!verificationRequestId) {
      setError(
        new Error('Configuration failed. verificationRequestId is not provided')
      );
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const result: TVerificationDocumentFull[] = [];
      const promises = ids.map(async (id) => {
        const item = await getDocumentData(id);
        result.push(item);
      });
      await Promise.all(promises);
      setResult(result);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error('Unknown error'));
      }
    }

    setIsLoading(false);
  }, [verificationRequestId, ids]);

  useEffect(() => {
    request();
  }, [request]);

  return [result, isLoading, error, request];
};
