import { styled, Typography } from "@mui/material";

type TStatus = {
  status?: "success" | "error" | "pending";
  width?: string;
  height?: number;
};

type LabelVariant = {
  variant?: "icon" | "button";
};

export const FileBlockWrapper = styled("label")<TStatus>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 12px 0 12px 8px;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => `${height}px` || "112px"};
  background: #ffffff;
  border: ${({ status = "pending" }) =>
    status === "pending"
      ? "1px dashed #8D959F"
      : status === "success"
      ? "1px solid #2CC469"
      : "1px solid #CF0000"};
  border-radius: 14px;
  cursor: pointer;
  &:hover {
    background: #fafafa;
  }
`;

export const DialogCustomLabel = styled("label")<LabelVariant>`
  display: flex;
  padding: ${({ variant = "button" }) =>
    variant === "button" ? "16px 60px" : "4px"};
  fill: ${({ variant = "button" }) =>
    variant === "button" ? "inherit" : "#8D959F"};
  color: ${({ variant = "button" }) =>
    variant === "button" ? "inherit" : "#8D959F"};
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const FileLabelInput = styled(Typography)<TStatus>`
  margin-right: 34px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  color: ${({ status }) =>
    status === "pending"
      ? "#8D959F"
      : status === "success"
      ? "#1C1F21"
      : "#CF0000"};
`;

export const FileLabelSubtitle = styled(Typography)<TStatus>`
  margin-right: 34px;
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #8d959f;
  @media screen and (max-width: 367px) {
    line-height: 14px;
  }
`;

export const FileLabelInputLine = styled(Typography)<TStatus>`
  // overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  line-height: 18px;
  @media screen and (max-width: 367px) {
    line-height: 14px;
  }
`;

export const FileSubLabelInput = styled(Typography)<{ isActive?: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  color: ${({ isActive }) => (isActive ? "#2CC369" : "#CF0000")};
  @media screen and (max-width: 367px) {
    font-size: 14px;
  }
`;

export const ImageFile = styled("img")`
  margin-left: 10px;
  max-width: 127px;
  max-height: 80px;
  // @media screen and (max-width: 367px) {
  //   max-width: 80px;
  // }
`;

export const ErrorFileLabel = styled(Typography)`
  margin: 3px 14px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.66;
  text-align: left;
  letter-spacing: 0.5px;
  color: #d32f2f;
`;
