import { Box, Stack } from "@mui/material";
import { Select, SelectData, TextField } from "mui-rff";
import { useTranslation } from "react-i18next";
import { required } from "../../../../../utils/validation";
import countries from "../../../data/counties.json";
import { useForm } from "react-final-form";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { getMaxDate } from "../../../utils/getMaxDate";

const nationalitySelectData: SelectData[] = countries.map((x) => ({
  label: x.name,
  value: x.code,
}));

export const UserInformation = () => {
  const [dateOfBirth, setDateOfBirth] = useState<Dayjs | null>(null);
  const form = useForm();
  const { t } = useTranslation("onBoarding", {
    keyPrefix: "UserInformation.fields",
  });

  const handleChangeDate = (value: any) => {
    setDateOfBirth(value);
    form.change("data.date_of_birth", value.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    if (form.getState().values?.data?.date_of_birth)
      setDateOfBirth(
        dayjs(form.getState().values?.data?.date_of_birth, "YYYY-MM-DD")
      );
  }, [form]);

  return (
    <Stack spacing={2} width='100%'>
      <TextField
        name='data.first_name'
        label={t("first_name")}
        required
        fieldProps={{ validate: required }}
      />
      <TextField
        name='data.last_name'
        label={t("last_name")}
        required
        fieldProps={{ validate: required }}
      />
      <DatePicker
        maxDate={getMaxDate()}
        defaultValue={getMaxDate()}
        value={dateOfBirth}
        onChange={(value: any) => handleChangeDate(value)}
        label={t("date_of_birth")}
        format='DD-MM-YYYY'
        slotProps={{
          textField: {
            required: true,
          },
        }}
      />
      <Select
        MenuProps={{ sx: { height: window.innerWidth < 400 ? 600 : "auto" } }}
        name='data.nationality'
        label={t("nationality")}
        required
        fieldProps={{ validate: required }}
        data={nationalitySelectData}
      />
      <Select
        MenuProps={{ sx: { height: window.innerWidth < 400 ? 600 : "auto" } }}
        name='data.middle_name'
        label={t("middle_name")}
        data={[{ label: "None", value: undefined }, ...nationalitySelectData]}
      />
      <Select
        MenuProps={{ sx: { height: window.innerWidth < 400 ? 600 : "auto" } }}
        name='data.country_of_birth'
        label={t("country_of_birth")}
        required
        fieldProps={{ validate: required }}
        data={nationalitySelectData}
      />
      <Box sx={{ display: "none" }}>
        <TextField name='data.date_of_birth' />
        <TextField name='data.phone_number' />
        <TextField name='data.email' />
      </Box>
    </Stack>
  );
};
