import { FC, memo } from 'react'
import { Box, Card, CardContent, Grid, Modal, Typography } from '@mui/material'
import { RequirementsWrapper } from './styles'
import { CloseBtnModal } from '../CloseBtnModal'
import { ImageBlock, TextBlock } from './UpperBlocks'
import { blurryCard, cardImage, reflectiveCard } from '../../../../assets/icons'
import { useTranslation } from 'react-i18next'

const listItems1: { id: number; text: string }[] = [
  { id: 1, text: 'government_issued' },
  { id: 2, text: 'original_full_size' },
  { id: 3, text: 'single_coloured_background' },
  { id: 4, text: 'coloured_images' },
]

const listItems2: { id: number; text: string }[] = [
  { id: 1, text: 'no_black_and_white' },
  { id: 2, text: 'no_edited' },
]

export const RequirementsModal: FC<{
  open: boolean
  handleClose: () => void
}> = memo(({ open, handleClose }) => {
  const { t } = useTranslation('onBoarding', {
    keyPrefix: 'RequirementsModal',
  })
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{
        '& .MuiBackdrop-root': {
          background: '#E3E3E3',
        },
      }}
    >
      <RequirementsWrapper>
        <CloseBtnModal onClick={handleClose} top='7px' right='6px' />
        <Box>
          <Typography
            fontWeight='800'
            fontSize='24px'
            lineHeight='32px'
            textAlign='center'
            letterSpacing='0.5px'
            color='#1C1F21'
          >
            {t('image_requirements')}
          </Typography>
        </Box>
        <Grid container spacing={1.5} columns={16} sx={{ marginTop: '24px' }}>
          <Grid item xs={8}>
            <Card
              variant='outlined'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                height: '154px',
                background: '#EEF3FE',
                borderRadius: '14px',
              }}
            >
              <CardContent
                sx={{
                  padding: '0',
                  paddingLeft: '14px',
                  '&:last-child': { paddingBottom: '12px' },
                }}
              >
                <ImageBlock isCheck label={t('good')} image={cardImage} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <Card
              variant='outlined'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                height: '154px',
                background: '#EEF3FE',
                borderRadius: '14px',
              }}
            >
              <CardContent
                sx={{
                  padding: '0',
                  paddingLeft: '16px',
                  paddingTop: '14px',
                  '&:last-child': { paddingBottom: '12px' },
                }}
              >
                <TextBlock listItems={listItems1} isCheck />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={1.5} columns={16} sx={{ marginTop: '16px' }}>
          <Grid item xs={8}>
            <Card
              variant='outlined'
              sx={{
                width: '100%',
                height: '120px',
                background: '#cf000015',
                borderRadius: '14px',
              }}
            >
              <CardContent
                sx={{
                  padding: '0',
                  paddingLeft: '14px',
                  '&:last-child': { paddingBottom: '12px' },
                }}
              >
                <ImageBlock label={t('not_blurry')} image={blurryCard} />
              </CardContent>
            </Card>
          </Grid>{' '}
          <Grid item xs={8}>
            <Card
              variant='outlined'
              sx={{
                width: '100%',
                height: '120px',
                background: '#cf000015',
                borderRadius: '14px',
              }}
            >
              <CardContent
                sx={{
                  padding: '0',
                  paddingLeft: '14px',
                  '&:last-child': { paddingBottom: '12px' },
                }}
              >
                <ImageBlock label={t('not_cut')} image={cardImage} isRotate />
              </CardContent>
            </Card>
          </Grid>{' '}
          <Grid item xs={8}>
            <Card
              variant='outlined'
              sx={{
                width: '100%',
                height: '120px',
                background: '#cf000015',
                borderRadius: '14px',
              }}
            >
              <CardContent
                sx={{
                  position: 'absolute',
                  padding: '0',
                  paddingLeft: '14px',
                  '&:last-child': { paddingBottom: '12px' },
                }}
              >
                <ImageBlock
                  label={t('not_reflective')}
                  isReflective
                  image={reflectiveCard}
                />
              </CardContent>
            </Card>
          </Grid>{' '}
          <Grid item xs={8}>
            <Card
              variant='outlined'
              sx={{
                width: '100%',
                height: '120px',
                background: '#cf000015',
                borderRadius: '14px',
              }}
            >
              <CardContent
                sx={{
                  padding: '0',
                  paddingLeft: '16px',
                  paddingTop: '14px',
                  '&:last-child': { paddingBottom: '12px' },
                }}
              >
                <TextBlock listItems={listItems2} />
              </CardContent>
            </Card>
          </Grid>{' '}
        </Grid>
      </RequirementsWrapper>
    </Modal>
  )
})
