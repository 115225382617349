import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CSSProperties, useState } from "react";
import { Content, ImageDialogContent, OpenHandler } from "./styles";
import PDFPreviewer from "../PdfPreview";
import PDFViewer from "../PDFViewer";
import { FileType } from "../FileInput";

export type Props = {
  dataLink: string;
  currentFileType?: string;
};

export const FileContent = (props: Props) => {
  const { dataLink, currentFileType } = props;
  const [isOpen, setIsOpen] = useState(false);

  let styles: CSSProperties = {
    height: "444px",
    marginBottom: currentFileType === FileType["application/pdf"] ? 0 : "24px",
    position: "relative",
    backgroundColor: "#ccc",
    backgroundPosition: "center center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  };
  let content = null;

  if (dataLink) {
    if (currentFileType === FileType.image)
      styles.backgroundImage = `url(${dataLink})`;
  }

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const allowOpen = Boolean(dataLink);

  return (
    <Box sx={styles}>
      {currentFileType === FileType["application/pdf"] ? (
        <PDFPreviewer path={dataLink} />
      ) : (
        content && <Content>{content}</Content>
      )}
      {allowOpen && <OpenHandler onClick={handleOpen} />}
      {isOpen && (
        <Dialog fullScreen open={isOpen} onClose={handleClose}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge='start'
                color='inherit'
                onClick={handleClose}
                aria-label='close'
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                File View
              </Typography>
            </Toolbar>
          </AppBar>
          <ImageDialogContent>
            {currentFileType === FileType["application/pdf"] ? (
              <PDFViewer path={dataLink} />
            ) : (
              <img
                src={dataLink}
                alt=''
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            )}
          </ImageDialogContent>
        </Dialog>
      )}
    </Box>
  );
};
