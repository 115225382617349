import { useCallback, useState } from "react";
import { TFormDataStep } from "../containers";
import {
  sendUIDataRequest,
  uploadDocumentData,
  uploadDocumentFile,
} from "../network";
import { useOnBoardingFormContext } from "./useOnBoardingFormContext";

export const useOnBoardingStepUpload = (): [
  TFormDataStep | null, // result
  boolean, // loading
  Error | null, // error
  (data: TFormDataStep) => Promise<boolean>, // upload
  (data: string) => Promise<boolean> // sendUiData
] => {
  const {
    verificationRequestId,
    documentIds,
    updateDocumentIds,
    setUploadedFiles,
  } = useOnBoardingFormContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<TFormDataStep | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const uploadDocument = useCallback(
    async (data: TFormDataStep): Promise<boolean> => {
      setLoading(true);
      setResult(null);
      setError(null);
      try {
        let documentId: string | null = null;
        if (data.documentId) {
          documentId = data.documentId;
        }
        const fielsToUpload: [string, File][] = [];

        if (data.files) {
          for (const fileType in data.files) {
            if (!data.files[fileType]) continue;
            if (data.files[fileType] instanceof File) {
              setUploadedFiles((prev: any) => ({ ...prev, [fileType]: true }));
              fielsToUpload.push([fileType, data.files[fileType] as File]);
            } else if (
              (data.files[fileType] instanceof FileList ||
                data.files[fileType] instanceof Array) &&
              (data.files[fileType] as FileList)?.length > 0
            ) {
              for (
                let i = 0;
                i < (data.files[fileType] as FileList).length;
                i++
              ) {
                fielsToUpload.push([
                  fileType,
                  (data.files[fileType] as FileList)[i],
                ]);
              }
            }
          }
        }

        if (data.data || (documentId === null && fielsToUpload.length > 0)) {
          const document = await uploadDocumentData(
            verificationRequestId,
            documentId || "0",
            data.documentType,
            data.data || {}
          );
          documentId = document.documentId;
        }

        if (fielsToUpload.length > 0 && documentId) {
          const uploadPromises: Promise<void>[] = [];
          fielsToUpload.forEach(([fileType, file]) => {
            uploadPromises.push(
              uploadDocumentFile(
                verificationRequestId,
                documentId as string,
                fileType,
                file
              )
            );
          });
          await Promise.all(uploadPromises);
        }
        if (documentId && !documentIds.includes(documentId)) {
          updateDocumentIds([...documentIds, documentId]);
        }
        setLoading(false);
        if (documentId) {
          setResult({
            ...data,
            documentId,
          });
        } else {
          setError(new Error("Nothing to send"));
        }
        return true;
      } catch (e: any) {
        setLoading(false);
        setError(e);
        return false;
      }
    },
    [documentIds, updateDocumentIds, verificationRequestId]
  );

  const sendUiData = useCallback(
    async (documentType: string): Promise<boolean> => {
      setLoading(true);
      setResult(null);
      setError(null);
      try {
        const document = await sendUIDataRequest(
          verificationRequestId,
          documentType
        );
        setLoading(false);
        if (!document?.id) {
          setError(new Error("Nothing to send"));
        }
        return true;
      } catch (e: any) {
        setLoading(false);
        setError(e);
        return false;
      }
    },
    [verificationRequestId]
  );

  return [result, loading, error, uploadDocument, sendUiData];
};
