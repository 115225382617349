import { FC } from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { ban, checkmark } from '../../../../../assets/icons'
import { useTranslation } from 'react-i18next'

export const TextBlock: FC<{
  isCheck?: boolean
  listItems: { id: number; text: string }[]
}> = ({ listItems, isCheck }) => {
  const { t } = useTranslation('onBoarding', {
    keyPrefix: 'SelfieRequirements',
  })
  return (
    <List>
      {listItems.map(({ id, text }) => (
        <ListItem
          key={id}
          sx={{ alignItems: 'baseline', padding: '0', paddingRight: '10px' }}
        >
          <ListItemIcon
            sx={{ position: 'relative', top: '3px', minWidth: '31px' }}
          >
            <img src={isCheck ? checkmark : ban} alt='mark' />
          </ListItemIcon>
          <ListItemText>
            <Typography
              fontFamily='Red Hat Display'
              fontWeight='400'
              fontSize='14px'
              lineHeight='22px'
              color='#1C1F21'
            >
              {t(`${text}`)}
            </Typography>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  )
}
