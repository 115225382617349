import { useCallback, useEffect, useMemo } from 'react';
import { TFormDataStep } from '../containers/OnBoardingFormContainer/OnBoardingFormProvider';
import { TVerificationAppElement } from '../models';
import { useOnBoardingFormContext } from './useOnBoardingFormContext';
import { useOnBoardingContext } from './useOnBoardingContext';
import { useVerificationRequest } from './useVerificationRequest';

export const useFormStep = (
  step: string
): {
  stepData: TFormDataStep | undefined;
  stepConfig: TVerificationAppElement | undefined;
  stepSubmit: (data?: TFormDataStep) => void;
  goToPrevious: (step: string) => void;
  readyToSend: boolean;
  countOfSteps: number;
} => {
  const { formData, formConfig, submitStepData, goToPrevious } =
    useOnBoardingFormContext();

  const { request } = useOnBoardingContext();

  const readyToSend = useMemo(() => {
    let ready = true;
    formConfig?.elements?.forEach((element, index) => {
      const stepIndex = index.toString();

      const docIsLoaded = element.config?.uiData
        ? request?.uiData?.includes(element.type)
        : !!formData[stepIndex]?.documentId;

      if (!docIsLoaded) {
        ready = false;
      }
    });

    return ready;
  }, [formData, formConfig, request]);

  const countOfSteps = useMemo(() => {
    return formConfig?.elements?.length || 0;
  }, [formConfig]);

  const stepData = useMemo(() => {
    return formData[step];
  }, [formData, step]);

  const stepConfig = useMemo(() => {
    return formConfig?.elements?.[parseInt(step)];
  }, [formConfig, step]);

  const stepSubmit = useCallback(
    (data?: TFormDataStep) => {
      submitStepData(step, data, readyToSend);
    },
    [step, submitStepData, readyToSend]
  );

  return {
    stepData,
    stepConfig,
    stepSubmit,
    goToPrevious,
    readyToSend,
    countOfSteps,
  };
};
