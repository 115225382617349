import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BackCardId, CardId } from "../../../../../assets/icons";
import { required } from "../../../../../utils/validation";
import { InputCamera } from "../../../../ui/components/InputCamera";

export const Id = () => {
  const { t } = useTranslation("onBoarding", { keyPrefix: "Id.fields" });
  return (
    <Stack spacing={4}>
      <InputCamera
        name='files.ID_FIRST_PAGE'
        label={t("front_photo")}
        icon={CardId}
        height={128}
        validate={required}
      />
      <InputCamera
        name='files.ID_SECOND_PAGE'
        label={t("back_photo")}
        icon={BackCardId}
        height={128}
        validate={required}
      />
    </Stack>
  );
};
