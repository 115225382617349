import { useState } from "react";

const defaultImageExtensions = ["png", "jpg", "jpeg", "bmp"];
const defaultCommonFileExtensions = ["pdf", ...defaultImageExtensions];
const defaultMaxSizeFile = 10; //Mb

export const useFileLoading = (
  input: any,
  handleDelete?: () => void,
  maxSize?: number,
  extensions?: string[]
): [
  (elem: any, cb?: () => void) => void,
  (e: React.ChangeEvent<HTMLAnchorElement>, document?: any) => void,
  Error | null
] => {
  const [error, setError] = useState<Error | null>(null);

  const onDelete = async (
    e: React.ChangeEvent<HTMLAnchorElement>,
    document?: any
  ) => {
    e.preventDefault();
    handleDelete?.();
  };

  const checkMaximumSize = (file: any) => {
    const fileSize = file?.size / 1024 / 1024; // in MB
    if (fileSize > (maxSize ?? defaultMaxSizeFile)) {
      throw new Error(
        `The maximum size is ${maxSize ?? defaultMaxSizeFile} MB`
      );
    }
  };

  const checkExtensions = (file: any) => {
    let fileExtension = file?.type?.split("/")?.pop() ?? "";
    if (fileExtension === "svg+xml") fileExtension = "svg";
    const allAllowedExtensions = extensions || defaultCommonFileExtensions;
    if (!allAllowedExtensions.includes(fileExtension)) {
      throw new Error(`Only pdf and images are accepted`);
    }
  };

  const onFileLoad = async (elem: any, cb?: () => void) => {
    try {
      setError(null);
      const file = elem.target.files[0];
      if (!file) {
        return;
      }
      if (file && input.value !== "") {
        onDelete(elem);
      }
      checkMaximumSize(file);
      checkExtensions(file);
      input.onChange(file);
    } catch (e: any) {
      setError(e.message);
    } finally {
      cb?.();
    }
  };

  return [onFileLoad, onDelete, error];
};
