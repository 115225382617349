import { VerificationAppDocumentType } from "../../models";
import { docTypesComponents } from "./docTypes";

export const DocumentForm = (props: { documentType: VerificationAppDocumentType }) => {
  const {documentType} = props;

  if (documentType in docTypesComponents) {
    const Component = docTypesComponents[documentType];
    return <Component />;
  }

  return null;
}