import { styled } from "@mui/material";

export const Content = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OpenHandler = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
`;

export const ImageDialogContent = styled("div")`
  width: 100%;
  height: calc(100% - 64px); // AppBar height
  display: flex;
  justify-content: center;
  align-items: center;
`;
