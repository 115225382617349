import { Avatar, Card, CardHeader, IconButton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useParams } from 'react-router-dom';
import { WidgetLayout } from '../../../../components/WidgetLayout';
import { useFormStep } from '../../hooks/useFormStep';
import { ChevronIcon } from '../../../../components/MuiIcons';
import { getAllowedDocumentIcon } from '../../utils/getAllowedDocumentIcon';

export const OnBoardingFormStepScreen = () => {
  const { stepIndex } = useParams();
  const { t } = useTranslation('onBoarding');
  const { stepConfig } = useFormStep(stepIndex || '-1');

  if (
    !stepConfig ||
    !stepConfig.allowed_documents ||
    stepConfig.allowed_documents.length === 0
  ) {
    return <div>Invalid step config</div>;
  }

  if (stepConfig.allowed_documents.length === 1) {
    return <Navigate replace to={stepConfig.allowed_documents[0]} />;
  }
  return (
    <WidgetLayout
      title={t(`${stepConfig.type}.title`)}
      subtitle={t(`${stepConfig.type}.subtitle`)}
    >
      <Stack spacing={2}>
        {stepConfig.allowed_documents.map((docType, index) => (
          <Card
            key={index}
            sx={{
              width: '100%',
              boxShadow: 'none',
              background: '#FFFFFF',
              borderRadius: '14px',
            }}
          >
            <CardHeader
              avatar={
                <Avatar
                  sx={{
                    background: '#EEF3FE',
                    border: '1px solid #D7E7FF',
                    boxShadow:
                      'inset 0px -30px 0px rgba(255, 255, 255, 0.15), inset 0px 5px 20px rgba(0, 81, 255, 0.05), inset 0px 0px 10px rgba(0, 102, 255, 0.15)',
                    color: '#2968EF',
                  }}
                >
                  {getAllowedDocumentIcon(docType)}
                </Avatar>
              }
              action={
                <IconButton
                  component={Link}
                  to={docType}
                  sx={{
                    position: 'relative',
                    left: '10px',
                    margin: '0',
                    borderRadius: '8px',
                    fontWeight: '700',
                    fontSize: '12px',
                    lineHeight: '16px',
                    letterSpacing: '0.5px',
                    color: '#2968EF',
                    textTransform: 'none',
                  }}
                >
                  <ChevronIcon
                    sx={{ position: 'relative', top: '2px', left: '3px' }}
                  />
                </IconButton>
              }
              title={t(`${docType}.title`)}
              sx={{
                '& .MuiCardHeader-action': {
                  margin: '0',
                  alignSelf: 'center',
                },
              }}
            />
          </Card>
        ))}
      </Stack>
    </WidgetLayout>
  );
};
