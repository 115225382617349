import { useCallback, useEffect } from "react";
import { Alert, Button } from "@mui/material";
import { useVerificationRequestSend } from "../../hooks/useVerificationRequestSend";
import { useOnBoardingContext } from "../../hooks/useOnBoardingContext";

export const SendButton = () => {
  const { requestId, request, reload } = useOnBoardingContext();
  const [result, loading, error, send] = useVerificationRequestSend();

  const handleSend = useCallback(() => {
    const id = requestId || request?.id;
    if (id) {
      send(id);
    }
  }, [requestId, request, send]);

  useEffect(() => {
    if (result) {
      reload();
    }
  }, [result, reload]);

  return (
    <>
      <Button
        variant='contained'
        onClick={handleSend}
        disabled={loading || Boolean(result)}
        sx={{ width: "160px", height: "44px" }}
      >
        Send
      </Button>
      {!loading && error && (
        <Alert severity='error'>{error.message || error.toString()}</Alert>
      )}
    </>
  );
};
