import {
  createContext,
  PropsWithChildren,
} from 'react';
import { useVerificationRequest } from '../../hooks/useVerificationRequest';
import {
  TVerificationRequest,
} from '../../models';

export type OnBoardingContextValue = {
  requestId: string | null;
  request: TVerificationRequest | null;
  requestLoading: boolean;
  requestError: Error | null;
  reload: () => void;
};

export const OnBoardingContext = createContext<OnBoardingContextValue>(
  {} as OnBoardingContextValue
);

export type TProps = PropsWithChildren<{
  verificationRequestId?: string;
  category?: string;
  appPublicKey?: string;
}>;

export const OnBoardingProvider = (props: TProps) => {
  const { verificationRequestId, category, appPublicKey, children } = props;
  const [request, requestLoading, requestError, reload] = useVerificationRequest({
    id: verificationRequestId,
    category,
    appPublicKey,
  });

  return (
    <OnBoardingContext.Provider
      value={{
        requestId: request?.id || verificationRequestId || null,
        request,
        requestLoading,
        requestError,
        reload,
      }}
    >
      {children}
    </OnBoardingContext.Provider>
  );
};
