import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { SelfieIcon } from '../../../../../assets/icons';
import { FFInputSelfie } from '../../../../ui/components/InputSelfie';
import { SelfieRequirementsModal } from './../../../../ui/components/SelfieRequirementsModal';
import { HelpCircleIcon } from '../../../../../components/MuiIcons';
import { required } from '../../../../../utils/validation';
import { useRequirementModal } from '../../../hooks/useRequirementModal';
import { useEffect } from 'react';
import { useOnBoardingFormContext } from '../../../hooks/useOnBoardingFormContext';

export const Selfie = () => {
  const { t } = useTranslation('onBoarding', { keyPrefix: 'Selfie.fields' });
  const { open, handleOpen, handleClose } = useRequirementModal();
  const { disableContinue } = useOnBoardingFormContext();

  useEffect(() => {
    return () => {
      disableContinue(false);
    };
  }, [disableContinue]);

  return (
    <Stack spacing={3} alignItems='center'>
      <FFInputSelfie
        name='files.SELFIE_FILE'
        label={t('selfie')}
        icon={SelfieIcon}
        height='171px'
        validate={required}
        onBlockContinue={disableContinue}
      />
      <Button onClick={handleOpen}>
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#2968EF',
            textTransform: 'none',
          }}
        >
          Image Requirements{' '}
          <HelpCircleIcon
            sx={{
              position: 'relative',
              top: '6px',
              left: '5px',
              width: '20px',
              height: '20px',
            }}
          />
        </Typography>
      </Button>
      <SelfieRequirementsModal open={open} handleClose={handleClose} />
    </Stack>
  );
};
