import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../auth/useAuth';

export const AuthorizedContainer = () => {
  const { authenticated, limited } = useAuth();

  if (!authenticated || limited) {
    return <Navigate to='/auth' />;
  }

  return <Outlet />;
};
