import qs from 'qs';

let appPublicKey: string = '';
let verificationToken: string | null = null;
let verificationRequestId: string | null = null;
let keepAuth: boolean = false;

if (window.location.search) {
  const query = qs.parse(window.location.search.slice(1));
  if (query.appPublicKey) {
    appPublicKey = query.appPublicKey.toString();
  }
  if (query.verificationToken) {
    verificationToken = query.verificationToken.toString();
  }
  if (query.verificationRequestId) {
    verificationRequestId = query.verificationRequestId.toString();
  }
  if (query.keepAuth) {
    keepAuth = query.keepAuth.toString() === 'true';
  }
}

export const config = {
  category: 'OnBoarding',
  appPublicKey,
  verificationToken,
  verificationRequestId,
  keepAuth,
}


