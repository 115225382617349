import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { WidgetLayout } from '../../../../components/WidgetLayout';
import { useEffect, useMemo } from 'react';
import { useOnBoardingFormContext } from '../../hooks/useOnBoardingFormContext';
import { ReactComponent as CheckmarkIcon } from '../../../../assets/icons/checkmark.svg';

export const OnBoardingFormTermsScreen = () => {
  const { formConfig } = useOnBoardingFormContext();
  const navigate = useNavigate();
  const acceptanceConfig = useMemo(() => {
    return formConfig?.rules;
  }, [formConfig]);

  useEffect(() => {
    if (!acceptanceConfig?.showAcceptanceScreen) {
      navigate('review');
    }
  }, [acceptanceConfig]);

  return (
    <WidgetLayout title='Complete your KYC' subtitle=''>
      <Stack
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        spacing={3}
      >
        <List>
          <ListItem alignItems='flex-start'>
            <ListItemIcon>
              <CheckmarkIcon />
            </ListItemIcon>
            <ListItemText>
              I am not a resident of the following countries{' '}
              {formConfig?.rules?.bannedCountries?.join(', ')}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckmarkIcon />
            </ListItemIcon>
            <ListItemText>I am 18 or older</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckmarkIcon />
            </ListItemIcon>
            <ListItemText>I accept Oneboard Terms and Conditions</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckmarkIcon />
            </ListItemIcon>
            <ListItemText>I accept Oneboard Privacy Policy</ListItemText>
          </ListItem>
        </List>
        <Box mb='32px'>
          <Button
            sx={{
              textTransform: 'inherit',
              backgroundColor: '#2968EF',
            }}
            variant='contained'
            component={Link}
            to='review'
          >
            Continue
          </Button>
        </Box>
      </Stack>
    </WidgetLayout>
  );
};
