import {
  Box,
  Button,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import Webcam from 'react-webcam'

export const Camera = styled(Webcam)<{ ref: React.RefObject<Webcam> }>`
  height: calc(100vh - 44px);
`

export const CameraWrapper = styled(Box)<{ isImg?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-mask: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.1) 25%,
      #fff 25% 100%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    linear-gradient(
      to left,
      rgba(0, 0, 0, 0.1) 0%,
      #fff 0% 100%,
      rgba(0, 0, 0, 0.1) 100%
    );
  mask: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.1) 25%,
      #fff 25% 100%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    linear-gradient(
      to left,
      rgba(0, 0, 0, 0.1) 0%,
      #fff 0% 100%,
      rgba(0, 0, 0, 0.1) 100%
    );
  -webkit-mask-size: 60% 50%;
  mask-size: 60% 50%;
  -webkit-mask-position: 50% 30%;
  mask-position: 50% 30%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    -webkit-mask-size: 50% 40%;
    mask-size: 50% 40%;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    -webkit-mask-size: 40% 30%;
    mask-size: 40% 30%;
    -webkit-mask-position: 50% 50px;
    mask-position: 50% 50px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    -webkit-mask-size: 30% 25%;
    mask-size: 30% 25%;
    -webkit-mask-position: 50% 30%;
    mask-position: 50% 30%;
  }
`

export const ModalWrap = styled(Stack)`
  width: 100%;
  height: calc(100vh - 38px);
`

export const CameraPanel = styled(Stack)`
  position: absolute;
  bottom: -5%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const PanelLabel = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1c1f21;
`

export const PanelButton = styled(Button)`
  position: relative;
  width: 64px;
  height: 64px;
  border: 4px solid #1c1f21;
  border-radius: 32px;
`

export const InnerPanelButton = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #1c1f21;
`

export const ContinueBtn = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  width: 200px;
  height: 54px;
  background: #2968ef;
  box-shadow: 0px 6px 13px rgba(6, 66, 195, 0.28),
    0px 1.80882px 2.52044px rgba(6, 66, 195, 0.0903767),
    0px 0.751293px 1.10391px rgba(6, 66, 195, 0.0892929),
    0px 0.271728px 0.468719px rgba(6, 66, 195, 0.094491);
  border-radius: 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
`

export const RetakeBtn = styled(Button)`
  height: 22px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #1c1f21;
  text-transform: none;
`
