import { Alert, Box, CircularProgress, Stack } from "@mui/material";
import { ReactNode } from "react";
import TimerIcon from "@mui/icons-material/Timer";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/CheckCircle";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { WidgetLayout } from "../../../../components/WidgetLayout";
import { QRScreen } from "../../components/QRScreen";
import { useOnBoardingContext } from "../../hooks/useOnBoardingContext";
import { VerificationRequestStatus } from "../../models";
import { SendButton } from "../../components/SendButton";

export const OnBoardingHomeScreen = () => {
  const { request, requestLoading, requestError } = useOnBoardingContext();

  let content: ReactNode | undefined;
  let icon: ReactNode | undefined;
  let title: string | undefined;
  let subtitle: string | undefined;
  if (requestLoading) {
    content = <CircularProgress size={50} />;
  } else if (requestError || !request) {
    title = "Error";
    subtitle = "Load request failed";
    const message = requestError
      ? requestError.message || requestError.toString()
      : "Unknown error";
    content = <Alert severity='error'>{message}</Alert>;
  } else if (
    request?.status === VerificationRequestStatus.AllowSendToVerification
  ) {
    title = "Ready to send";
    subtitle = "Please send your request ro verification";
    content = <SendButton />;
  } else if (request?.status === VerificationRequestStatus.SentToVerification) {
    title = "Pending";
    subtitle = "Your request on verification";
    icon = <TimerIcon color='success' fontSize='inherit' />;
  } else if (request?.status === VerificationRequestStatus.Canceled) {
    title = "Declined";
    subtitle = "Your request has been declined";
    icon = <CancelIcon color='error' fontSize='inherit' />;
  } else if (request?.status === VerificationRequestStatus.Accepted) {
    title = "Accepted";
    subtitle = "Your request has been accepted";
    icon = <CheckIcon color='success' fontSize='inherit' />;
  } else if (request?.status === VerificationRequestStatus.Completed) {
    title = "Completed";
    subtitle = "Your request has been completed";
    icon = <CheckIcon color='success' fontSize='inherit' />;
  } else if (
    request?.status === VerificationRequestStatus.SentByUserToFingerPrinting
  ) {
    title = "Fingerprint";
    subtitle = "Your request has been sent to fingerprinting";
    icon = <FingerprintIcon color='success' fontSize='inherit' />;
  } else if (
    request?.status !== VerificationRequestStatus.New &&
    request?.status !== VerificationRequestStatus.InformationRequested
  ) {
    title = "Unknown status";
    subtitle = `Your request has unknown status ${request?.status}`;
    icon = <ReportProblemIcon color='error' fontSize='inherit' />;
  }

  if (content || title || subtitle || icon) {
    return (
      <WidgetLayout title={title} subtitle={subtitle}>
        <Stack
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
        >
          {icon && (
            <Box
              sx={{
                width: "100%",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "120px",
              }}
            >
              {icon}
            </Box>
          )}
          {content}
        </Stack>
      </WidgetLayout>
    );
  }

  return <QRScreen email={request?.email} />;
};
