import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import { WidgetLayout } from "../../../../components/WidgetLayout";

import { useGetQRCode } from "../../hooks/useGetQRCode";
import { useOnBoardingContext } from "../../hooks/useOnBoardingContext";
import {
  InstructionClientStatement,
  InstructionIdentityDocument,
  InstructionQuestionnaire,
  InstructionSelfie,
} from "../../../../components/MuiIcons";
import { FC, ReactElement, useEffect } from "react";
import { API_BASE_URL, ENV_FROM_URL } from "../../../../constants";
import { useCheckUser } from "../../hooks/useCheckEmail";
import useLocalStorage from "../../../ui/hooks/useLocalStorage";

const instructions: { icon: ReactElement; title: string; subtitle: string }[] =
  [
    {
      icon: <InstructionQuestionnaire />,
      title: "Questionnaire",
      subtitle: "Please take a few moments to fill out the questionnaire",
    },
    {
      icon: <InstructionClientStatement />,
      title: "Client Statement",
      subtitle:
        "Confirm your beneficial ownership and eventual political exposure",
    },
    {
      icon: <InstructionIdentityDocument />,
      title: "Identity document",
      subtitle:
        "Provide your identity document (International Passport, ID card) for visual scanning. Ensure that it is not expired or physically damaged",
    },
    {
      icon: <InstructionSelfie />,
      title: "Selfie",
      subtitle:
        "Go through our liveness-detection check. This involves turning your head in front of a camera for a few minutes",
    },
  ];

export const QRScreen: FC<{ email: string | undefined }> = ({ email }) => {
  const { requestId, requestLoading, requestError } = useOnBoardingContext();
  const [data, qrLoading, qrError] = useGetQRCode(requestId || undefined);
  const [userEmail, setUserEmail] = useLocalStorage("email", null);
  const [user, userLoading, userError] = useCheckUser(userEmail);

  useEffect(() => {
    if (email || user?.email) {
      setUserEmail(email || user.email);
    }
  }, [user, setUserEmail, email]);

  const loading = qrLoading || requestLoading || userLoading;
  const error = qrError || requestError || userError;

  return (
    <WidgetLayout title='Complete your KYC'>
      <Stack
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        spacing={3}
      >
        {loading && <CircularProgress size={50} />}
        {error !== null && (
          <Alert severity='error'>{error.message || error.toString()}</Alert>
        )}
        {data && (
          <>
            {API_BASE_URL !== ENV_FROM_URL.PROD_ENV ? (
              <>
                <Box
                  sx={{
                    padding: "25px",
                    background: "#fff",
                    boxShadow:
                      "0px 14.770000457763672px 40.39707946777344px 0px #0000000a, 0px 6.139999866485596px 16.778879165649414px 0px #00000008, 0px 2.2200000286102295px 6.068591594696045px 0px #00000006",
                    borderRadius: "12px",
                  }}
                >
                  <Stack
                    direction='row'
                    spacing={2}
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <Box>
                      <Typography fontWeight='bold'>
                        Already on OneBoard?
                      </Typography>
                      <Typography variant='body2'>
                        Scan QR code via OneBoard app
                        <br />
                        to complete your KYC in two clicks.
                      </Typography>
                    </Box>
                    <QRCode value={data.data} size={120} />
                  </Stack>
                </Box>
                <Typography textAlign='center'>
                  or
                  <br />
                  Not a OneBoard user yet? Please follow these steps
                </Typography>
              </>
            ) : (
              <Typography textAlign='center'>
                Please follow these steps
              </Typography>
            )}
            {instructions.map((instruction, index) => (
              <Stack direction='row' spacing={2} key={index} width='100%'>
                {instruction.icon}
                <Box>
                  <Typography fontWeight='bold'>{instruction.title}</Typography>
                  <Typography variant='body2'>
                    {instruction.subtitle}
                  </Typography>
                </Box>
              </Stack>
            ))}
            <Box mb='32px'>
              <Button
                sx={{
                  textTransform: "inherit",
                  backgroundColor: "#2968EF",
                }}
                variant='contained'
                component={Link}
                to={
                  email || userEmail || user?.email
                    ? "/onboarding-form"
                    : "/add-email"
                }
              >
                Continue
              </Button>
            </Box>
          </>
        )}
      </Stack>
    </WidgetLayout>
  );
};
