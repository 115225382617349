import { IconButton, styled } from '@mui/material'
import { CloseIcon } from '../../../../components/MuiIcons'

export const CloseButton = styled(IconButton)<{ top?: string; right?: string }>`
  position: absolute;
  top: ${({ top }) => (top ? top : '11px')};
  right: ${({ right }) => (right ? right : '18px')};
  z-index: 10;
  color: '#1C1F21';
`

export const CloseIconWrap = styled(CloseIcon)`
  position: relative;
  top: 2px;
  margin-left: 10px;
  width: 24px;
  height: 24px;
  color: #1c1f21;
`
