import { Outlet } from 'react-router-dom';
import { config } from '../../../../config';
import { OnBoardingProvider } from './OnBoardingProvider';

export * from './OnBoardingProvider';

export const OnBoardingContainer = () => {
  return (
    <OnBoardingProvider
      verificationRequestId={config.verificationRequestId || ''}
      category={config.category}
      appPublicKey={config.appPublicKey}
    >
      <Outlet />
    </OnBoardingProvider>
  );
};
