import { Component, createContext } from "react";
import { config } from "../config";
import {
  restoreVerificationSession,
  saveVerificationSession,
} from "../modules/onBoarding/network";
import { mainClient } from "../network/mainClient";
import { authStorage } from "./authStorage";
import { TokenData } from "./types";

export type AuthState = {
  loading: boolean;
  loaded: boolean;
  authenticated: boolean;
  authorizedCompanyId: string | null;
  limited: boolean;
};

export type AuthContextValue = AuthState & {
  requestSms: typeof mainClient.auth.requestSms;
  requestEmail: typeof mainClient.auth.requestEmail;
  authWithCode: typeof mainClient.auth.authWithCode;
  confirmEmail: typeof mainClient.auth.confirmEmail;
  setTokenData: (tokenData: TokenData) => void;
  logout: () => Promise<boolean>;
};

export const AuthContext = createContext<AuthContextValue>(
  {} as AuthContextValue
);

export class AuthProvider extends Component<{}, AuthState> {
  state = {
    loading: true,
    loaded: false,
    authenticated: false,
    authorizedCompanyId: null,
    limited: false,
  };

  componentDidMount() {
    this.restore();
  }

  restore = async () => {
    const data = await authStorage.getAuthData();
    let authenticated = data !== null;
    let limited = false;
    if (config.verificationToken) {
      const restoreData = await restoreVerificationSession(
        config.verificationToken
      );
      if (restoreData.token) {
        authenticated = true;
        limited = true;
        authStorage.setAuthData(restoreData.token);
      }
      if (
        restoreData.verificationRequestId &&
        restoreData.verificationRequestId !== "0"
      ) {
        config.verificationRequestId = restoreData.verificationRequestId;
      }
    }
    this.setState({
      loading: false,
      loaded: true,
      authenticated,
      limited,
    });
  };

  requestSms = async (phone: string) => {
    return await mainClient.auth.requestSms(phone);
  };

  requestEmail = async (email: string) => {
    return await mainClient.auth.requestEmail(email);
  };

  confirmEmail = async (email: string, code: string, salt: string) => {
    return await mainClient.auth.confirmEmail(email, code, salt);
  };

  authWithCode = async (phone: string, code: string, salt: string) => {
    const result = await mainClient.auth.authWithCode(phone, code, salt);
    authStorage.setAuthData(result);
    if (config.verificationToken && config.verificationRequestId) {
      try {
        await saveVerificationSession(
          config.verificationToken,
          config.verificationRequestId
        );
      } catch (e) {
        console.error("Save verification session ERROR: ", e);
      }
    }
    this.setState({
      authenticated: true,
      limited: false,
    });
    return result;
  };

  logout = async () => {
    authStorage.clear();
    this.setState({
      authenticated: false,
      authorizedCompanyId: null,
      limited: false,
    });
    return true;
  };

  setTokenData = (tokenData: TokenData) => {
    if (!tokenData) {
      throw new Error("Token Data not provided");
    }
    authStorage.setAuthData(tokenData);
    this.setState({
      authorizedCompanyId: tokenData.companyId || null,
      limited: false,
    });
  };

  render() {
    const { children } = this.props;

    const contextValue: AuthContextValue = {
      ...this.state,
      requestSms: this.requestSms,
      confirmEmail: this.confirmEmail,
      requestEmail: this.requestEmail,
      authWithCode: this.authWithCode,
      setTokenData: this.setTokenData,
      logout: this.logout,
    };

    return (
      <AuthContext.Provider value={contextValue}>
        {children}
      </AuthContext.Provider>
    );
  }
}
