import { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { ValidationErrors } from "final-form";
import { TextField } from "mui-rff";
import { Alert, Stack, Box, Button } from "@mui/material";
import { useRequestSms } from "../../auth/useRequestSms";
import { useLogin } from "../../auth/useLogin";
import { ResendSms } from "./ResendSms";
import { WidgetLayout } from "../../components/WidgetLayout";
import { SubmitBtn } from "../../components/SubmitBtn";
import useLocalStorage from "../../modules/ui/hooks/useLocalStorage";
import {
  AML_STORAGE,
  STEPPER_COMPLETED,
} from "../../modules/onBoarding/models";
import { filterInput, numbersRegExp } from "../../utils/validation";
import { parseSignInError } from "../../utils/parseSignInError";

type FormValues = {
  phoneNumber?: string;
  code?: string;
};

export const SigninScreen = () => {
  const [step, setStep] = useState<"phone" | "code">("phone");
  const [phoneNumber, setPhoneNumber] = useLocalStorage("phone", null);
  const [, setUserEmail] = useLocalStorage("email", null);
  const [smsResult, smsLoading, smsError, smsRequest] = useRequestSms();
  const [, loginLoading, loginError, loginRequest] = useLogin();

  const handleChangeStep = useCallback(() => {
    setStep("phone");
  }, []);

  useEffect(() => {
    if (smsResult) {
      setStep("code");
    }
  }, [smsResult]);

  const onSubmit = useCallback(
    (formData: FormValues) => {
      if (step === "phone") {
        localStorage.setItem(AML_STORAGE, "false");
        localStorage.setItem(STEPPER_COMPLETED, "{}");
        if (formData.phoneNumber) {
          let phoneNumber = "+" + formData.phoneNumber.split("+").join("");
          smsRequest(phoneNumber);
          setPhoneNumber(phoneNumber);
        }
      } else if (step === "code") {
        if (phoneNumber && formData.code && smsResult?.salt) {
          setUserEmail(null);
          loginRequest(phoneNumber, formData.code.trim(), smsResult.salt);
        }
      }
    },
    [smsResult, smsRequest, loginRequest, setPhoneNumber, phoneNumber, step]
  );

  const validate = useCallback(
    (formData: FormValues) => {
      const errors: ValidationErrors = {};

      if (step === "phone" && !formData.phoneNumber) {
        errors.phoneNumber = true;
      }

      if (step === "code" && !formData.code) {
        errors.code = true;
      }

      return errors;
    },
    [step]
  );

  const resendSms = useCallback(() => {
    if (phoneNumber) {
      return smsRequest(phoneNumber);
    }
  }, [smsRequest, phoneNumber]);

  const initialValues = useMemo(() => {
    return !phoneNumber ? {} : { phoneNumber: phoneNumber };
  }, [phoneNumber]);

  const error = smsError || loginError;

  return (
    <WidgetLayout
      title={
        step === "code"
          ? "Enter verification code"
          : "Please enter your phone number"
      }
      subtitle={
        step === "code"
          ? `Sent to ${phoneNumber}.`
          : "We will send a verification code to this number"
      }
      action={
        step === "code" && (
          <Button
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "center",
              color: "#2968EF",
              textTransform: "capitalize",
            }}
            onClick={handleChangeStep}
          >
            Change
          </Button>
        )
      }
    >
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Stack
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              <Stack
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                width='100%'
              >
                {step === "phone" && (
                  <TextField
                    placeholder='+9998887766'
                    label='Phone Number'
                    name='phoneNumber'
                    required={true}
                    type='tel'
                    value={phoneNumber}
                    inputProps={{
                      onChange: (e) =>
                        filterInput(
                          (e.target as HTMLTextAreaElement).value,
                          numbersRegExp,
                          setPhoneNumber
                        ),
                    }}
                  />
                )}
                {step === "code" && (
                  <TextField
                    name='code'
                    required={true}
                    autoFocus
                    sx={{ width: "150px" }}
                    inputProps={{
                      style: {
                        paddingBottom: "14px",
                        textAlign: "center",
                        fontWeight: "800",
                        fontSize: "20px",
                        lineHeight: "28px",
                        letterSpacing: "0.5px",
                        color: "#1C1F21",
                      },
                    }}
                    type='tel'
                    variant='standard'
                  />
                )}
                <Box mt={4} />
                {step === "code" && <ResendSms onResend={resendSms} />}
              </Stack>
              <SubmitBtn disabled={smsLoading || loginLoading}>
                Continue
              </SubmitBtn>
              <Box mt={2} />
              {error !== null && (
                <Alert severity='error'>
                  {parseSignInError(
                    error.toString(),
                    "The code has expired. Please resend it and enter the new one."
                  )}
                </Alert>
              )}
            </Stack>
          </form>
        )}
      />
    </WidgetLayout>
  );
};
