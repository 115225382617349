import { useCallback, useEffect, useState } from "react";
import { getUserData } from "../network";

export const useCheckUser = (
  userEmail?: string
): [
  any | null,
  boolean,
  Error | null,
  (verificationRequestId?: string) => void
] => {
  const [result, setResult] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const user = await getUserData();
      setResult(user);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error("Unknown error"));
      }
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!userEmail) {
      request();
    }
  }, [request, userEmail]);

  return [result, isLoading, error, request];
};
