import { useCallback, useMemo } from "react";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Box, Grid, Stack } from "@mui/material";
import { DialogCustomButton } from "./styles";
import { FileContent } from "../FileContent";
import { useFileLoading } from "../../hooks/useFileLoading";
import { DialogCustomLabel } from "../FileBlock/styles";
import AutorenewIcon from "@mui/icons-material/Autorenew";

export type Props = {
  onClose: (value: string) => void;
  open: boolean;
  dataLink: string;
  previewImg: string;
  namePrefix: string;
  input: any;
  InputComponent?: any;
  currentFileType?: string;
};

export const FilePreviewDialog = (props: Props) => {
  const {
    onClose,
    open,
    dataLink,
    previewImg,
    input,
    InputComponent,
    namePrefix,
    currentFileType,
  } = props;
  const [onFileLoad] = useFileLoading(input);

  const handleCancel = useCallback(() => {
    onClose("cancel");
  }, [onClose]);

  const contentLink = useMemo(() => {
    return previewImg || dataLink;
  }, [previewImg, dataLink]);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "800px",
          borderRadius: "16px",
        },
      }}
      maxWidth='xs'
      open={open}
      id='document-review-dialog'
      onClose={handleCancel}
      keepMounted
    >
      <FileContent currentFileType={currentFileType} dataLink={contentLink} />
      <DialogActions sx={{ padding: "0 32px 32px" }}>
        <Grid
          direction='row'
          container
          flexDirection='row'
          justifyContent='center'
          alignItems='center'
          spacing={2}
        >
          <Grid item>
            <DialogCustomButton
              autoFocus
              onClick={handleCancel}
              variant='outlined'
            >
              Close
            </DialogCustomButton>
          </Grid>
          <Grid item>
            <DialogCustomButton variant='contained'>
              <DialogCustomLabel htmlFor={namePrefix}>
                Replace
                <AutorenewIcon />
              </DialogCustomLabel>
            </DialogCustomButton>
            {InputComponent && (
              <InputComponent
                onChange={onFileLoad}
                accept='.jpeg, .jpg, .png, .bmp, .pdf'
                name={`${namePrefix}.inputData`}
                id={namePrefix}
                style={{
                  zIndex: "-1",
                  position: "absolute",
                  opacity: "0",
                  width: 0,
                }}
              />
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
