import { FC } from 'react'
import { Button } from '@mui/material'

export const SubmitBtn: FC<{
  children: string | number
  disabled?: boolean
}> = ({ children, disabled }) => {
  return (
    <Button
      type='submit'
      fullWidth
      variant='contained'
      disabled={disabled}
      sx={{
        padding: '11px 24px',
        width: '160px',
        height: '44px',
        background: '#2968EF',
        boxShadow:
          '0px 6px 13px rgba(6, 66, 195, 0.28), 0px 1.80882px 2.52044px rgba(6, 66, 195, 0.0903767), 0px 0.751293px 1.10391px rgba(6, 66, 195, 0.0892929), 0px 0.271728px 0.468719px rgba(6, 66, 195, 0.094491)',
        borderRadius: '12px',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '22px',
        letterSpacing: '0.5px',
        color: '#FFFFFF',
      }}
    >
      {children}
    </Button>
  )
}
