import { config } from '../config';
import { TokenData } from './types';

const AUTH_DATA_KEY = 'auth';

class AuthStorage {
  private data: TokenData | null = null;
  private syncStorage = config.keepAuth;

  getAuthData = async (): Promise<TokenData | null> => {
    if (this.syncStorage) {
      const data = localStorage.getItem(AUTH_DATA_KEY);
      if (data) {
        try {
          const parsedData = JSON.parse(atob(data));
          return parsedData as TokenData;
        } catch (e) {}
      }
  
      return null;
    }
    return this.data;
  };

  setAuthData = async (data: TokenData) => {
    if (this.syncStorage) {
      const str = btoa(JSON.stringify(data));
      return localStorage.setItem(AUTH_DATA_KEY, str);
    }
    this.data = data;
  };

  clear = async () => {
    this.data = null;
    if (this.syncStorage) {
      localStorage.removeItem(AUTH_DATA_KEY);
    }
  };
}

export const authStorage = new AuthStorage();
