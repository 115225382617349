import { useCallback, useEffect, useState } from 'react';
import { TVerificationApp } from '../models';
import {
  getVerificationApp,
} from '../network';

export const useVerificationApp = (
  appPublicKey: string
): [TVerificationApp | null, boolean, Error | null, () => void] => {
  const [result, setResult] = useState<TVerificationApp | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async () => {
    if (!appPublicKey) {
      setError(new Error('Configuration failed. appPublicKey is not provided'));
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const result = await getVerificationApp(appPublicKey);
      setResult(result);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error('Unknown error'));
      }
    }

    setIsLoading(false);
  }, [appPublicKey]);

  useEffect(() => {
    request();
  }, [request]);

  return [result, isLoading, error, request];
};
