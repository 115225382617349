import { Step, StepIconProps, StepLabel, Stepper } from "@mui/material";
import { FC, useMemo } from "react";
import { QontoConnector, QontoStepIconRoot } from "./styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed && !active ? (
        <CheckCircleIcon color='primary' />
      ) : active ? (
        <div className='QontoStepIcon-active' />
      ) : (
        <div className='QontoStepIcon-circle' />
      )}
    </QontoStepIconRoot>
  );
}

export const WidgetStepper: FC<{
  countOfSteps?: number;
  currentStep?: number;
  completed?: {
    [k: number]: boolean;
  };
}> = ({ countOfSteps = 6, currentStep, completed }) => {
  const widgetSteps = useMemo(
    () => [...Array(countOfSteps + 1).keys()],
    [countOfSteps]
  );

  return (
    <Stepper
      connector={<QontoConnector />}
      style={{ marginBottom: 32, marginTop: -40 }}
      activeStep={currentStep}
      alternativeLabel
    >
      {widgetSteps.map((label: any, index) => (
        <Step key={label} completed={completed && completed[index]}>
          <StepLabel
            StepIconComponent={(props: any) =>
              QontoStepIcon({
                ...props,
                completed: completed && completed[index],
              })
            }
          >
            {""}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
