import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from './langs/en.json';

export type Langs = {
  [lang: string]: {
    [ns: string]: {
      [key: string]: any; 
    }
  };
}

export const defaultLang = 'en';
let resources: Langs = {
  en,
}


i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: defaultLang,
    defaultNS: 'translation',
    fallbackNS: 'translation',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });


export const registerLocales = (locales: Langs) => {
  for (const lang in locales) {
    for (const ns in locales[lang]) {
      i18n.addResourceBundle(lang, ns, locales[lang][ns], true, true);
    }
  }
}