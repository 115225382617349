import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useLoadDocuments } from "../../hooks/useLoadDocuments";
import {
  TOnboardingConfig,
  TVerificationApp,
  TVerificationDocumentDataItem,
  TVerificationDocumentFull,
  TVerificationRequest,
  VerificationAppDocumentType,
} from "../../models";
import { setDocumentsToRequest } from "../../network";

export type TTFormDataStepFile = {
  inputData?: FileList | File | null;
  storedData?: TVerificationDocumentDataItem;
};

export type TFormDataStep = {
  documentType: VerificationAppDocumentType;
  documentId?: string;
  hasChanges?: boolean;
  data?: {
    [key: string]: any;
  };
  files?: {
    [key: string]: TTFormDataStepFile;
  };
};

export type TFormData = {
  [step: string]: TFormDataStep;
};

export type OnBoardingFormContextValue = {
  restoring: boolean;
  formConfig?: TOnboardingConfig;
  formData: TFormData;
  verificationRequestId: string;
  documentIds: string[];
  submitStepData: (
    step: string,
    data?: TFormDataStep,
    toReview?: boolean
  ) => void;
  goToPrevious: (step: string) => void;
  isDisabledContinue: boolean;
  disableContinue: (value: boolean) => void;
  updateDocumentIds: (ids: string[]) => void;
  files: any;
  uploadedFiles: any;
  setUploadedFiles: Dispatch<SetStateAction<any>>;
};

export const OnBoardingFormContext = createContext<OnBoardingFormContextValue>(
  {} as OnBoardingFormContextValue
);

export type TProps = PropsWithChildren<{
  app: TVerificationApp;
  request: TVerificationRequest | any;
}>;

export const OnBoardingFormProvider = (props: TProps) => {
  const { request, app, children } = props;
  const navigate = useNavigate();
  const initialDocumentsIds = useMemo(
    () => request?.documents?.map((x: any) => x.document.documentId) || [],
    [request.documents]
  );

  const files = useMemo(() => {
    const files: any = {};
    request?.documents.forEach((document: TVerificationDocumentFull) => {
      const documentType = document.document.documentType;
      files[documentType] = {};
      document.data.forEach((data) => {
        if (data.type === "file") {
          const declineReason = document.document.declineReason;
          files[documentType].declineReason = declineReason.length
            ? declineReason
            : null;
          const dataId = data.dataId;
          files[documentType][dataId] = data;
        }
      });
    });
    return files;
  }, [request]);

  const [documents] = useLoadDocuments(request.id, initialDocumentsIds);
  const [restoring, setRestoring] = useState(true);
  const [formData, setFormData] = useState<TFormData>({});
  const [isDisabledContinue, disableContinue] = useState(false);
  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);

  useEffect(() => {
    setDocumentIds(initialDocumentsIds);
  }, [initialDocumentsIds]);

  const goToNext = useCallback(
    (currentStep: string) => {
      const currentIndex = parseInt(currentStep);
      if (
        currentIndex + 1 <
        (app?.settings?.onboardingConfigParsed?.elements?.length || -1)
      ) {
        navigate(String(currentIndex + 1));
      } else {
        navigate("review");
      }
    },
    [navigate]
  );

  const goToPrevious = useCallback(
    (currentStep: string) => {
      const currentIndex = parseInt(currentStep);
      if (currentIndex - 1 >= 0) {
        navigate(String(currentIndex - 1));
      }
    },
    [navigate]
  );

  const submitStepData = useCallback(
    (step: string, data?: TFormDataStep, toReview?: boolean) => {
      if (data) {
        setFormData((state) => ({
          ...state,
          [step]: data,
        }));
      }
      if (toReview) {
        navigate("review");
      } else {
        goToNext(step);
      }
    },
    [setFormData, goToNext, navigate]
  );

  useEffect(() => {
    // Go to first step
    // navigate('0');
  }, [navigate]);

  const updateDocumentIds = useCallback(
    (newIds: string[]) => {
      setDocumentIds(newIds);
      setDocumentsToRequest(request.id, newIds);
    },
    [request.id]
  );

  const contextValue: OnBoardingFormContextValue = useMemo(() => {
    const result: OnBoardingFormContextValue = {
      submitStepData,
      goToPrevious,
      restoring,
      formData,
      verificationRequestId: request.id,
      isDisabledContinue,
      disableContinue,
      documentIds,
      updateDocumentIds,
      files,
      uploadedFiles,
      setUploadedFiles,
    };

    if (app?.settings?.onboardingConfigParsed) {
      result.formConfig = app.settings.onboardingConfigParsed;
    }

    return result;
  }, [
    restoring,
    app,
    request.id,
    formData,
    submitStepData,
    goToPrevious,
    isDisabledContinue,
    documentIds,
    updateDocumentIds,
    files,
    uploadedFiles,
    setUploadedFiles,
  ]);

  useEffect(() => {
    if (documents && contextValue.formConfig) {
      const map: { [type: string]: TVerificationDocumentFull } = {};
      documents.forEach((document) => {
        map[document.document.documentType] = document;
      });
      const newFormData: TFormData = {};
      contextValue.formConfig.elements?.forEach((element, index) => {
        if (element.allowed_documents) {
          let existDocument: TVerificationDocumentFull | null = null;
          element.allowed_documents.forEach((docType) => {
            if (docType in map) {
              existDocument = map[docType];
            }
          });
          if (existDocument) {
            const doc: TVerificationDocumentFull = existDocument; // TODO: пришлось временно добавить из-за странного бага в TS который опредял тип как never
            const formStepValue: TFormDataStep = {
              documentType: doc.document.documentType,
              documentId: doc.document.documentId,
            };
            doc.data.forEach((item) => {
              if (item.type === "file") {
                formStepValue.files = formStepValue.files || {};
                formStepValue.files[item.dataId] = {
                  storedData: item,
                };
              } else if (item.type === "binary") {
                formStepValue.data = item.dataParsed;
              }
            });
            newFormData[index.toString()] = formStepValue;
          }
        }
      });

      setFormData((state) => ({
        ...state,
        ...newFormData,
      }));
      setRestoring(false);
    }
  }, [documents, contextValue.formConfig, setFormData, setRestoring]);

  return (
    <OnBoardingFormContext.Provider value={contextValue}>
      {children}
    </OnBoardingFormContext.Provider>
  );
};
