/* eslint-disable no-extend-native */

if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (
      Object.prototype.toString.call(str).toLowerCase() === '[object regexp]'
    ) {
      return this.replace(str, newStr as string);
    }

    // If a string
    return this.replace(new RegExp(str, 'g'), newStr as string);
  };
}

export {};