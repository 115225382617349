import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { HelpCircleIcon } from "../../../../../components/MuiIcons";
import { InputCamera } from "../../../../ui/components/InputCamera";
import { RequirementsModal } from "../../../../ui/components/RequirementsModal";
import { BackCardId, CardId } from "../../../../../assets/icons";
import { required } from "../../../../../utils/validation";
import { useRequirementModal } from "./../../../hooks/useRequirementModal";

export const DriverLicence = () => {
  const { t } = useTranslation("onBoarding", {
    keyPrefix: "DriverLicence.fields",
  });

  const { open, handleOpen, handleClose } = useRequirementModal();

  return (
    <>
      <Stack spacing={2} alignItems='center'>
        <InputCamera
          name='files.DRIVER_LICENCE_FIRST_PAGE'
          label={t("front_photo")}
          icon={CardId}
          height={128}
          validate={required}
        />
        <InputCamera
          name='files.DRIVER_LICENCE_SECOND_PAGE'
          label={t("back_photo")}
          icon={BackCardId}
          height={128}
          validate={required}
        />
        <Stack
          flexDirection='column'
          alignItems='center'
          gap='12px'
          sx={{ margin: "24px 0 32px" }}
        >
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "22px",
              textAlign: "center",
              color: "#1C1F21",
            }}
          >
            Upload <b>.pdf / .jpeg / .jpg / .png</b> file no more 5MB
          </Typography>
          <Button onClick={handleOpen}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#2968EF",
                textTransform: "none",
              }}
            >
              {t("image_requirements")}
              <HelpCircleIcon
                sx={{
                  position: "relative",
                  top: "6px",
                  left: "5px",
                  width: "20px",
                  height: "20px",
                }}
              />
            </Typography>
          </Button>
        </Stack>
      </Stack>
      <RequirementsModal open={open} handleClose={handleClose} />
    </>
  );
};
