import { FC, useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import { Box, Modal } from "@mui/material";
import { CardId } from "../../../../assets/icons";
import { urlToFile } from "../../../../utils/urlToFile";
import {
  Camera,
  CameraWrapper,
  ModalWrap,
  CameraPanel,
  PanelLabel,
  PanelButton,
  InnerPanelButton,
  ContinueBtn,
  RetakeBtn,
} from "./styles";
import { Field, useField } from "react-final-form";
import { FileBlock } from "../FileBlock";
import { CloseBtnModal } from "../CloseBtnModal";

export const InputCamera: FC<{
  name: string;
  label: string;
  icon?: string;
  width?: string;
  height?: number;
  validate?: (value?: string | undefined) => string | undefined;
}> = ({ name, label, icon = CardId, width, height, validate }) => {
  const { input } = useField(name);

  const [open, setOpen] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const webcamRef = useRef<Webcam>(null);

  const handleOpenModal = useCallback(() => {
    if (imgSrc) {
      return;
    }
    setOpen(true);
  }, [imgSrc]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    if (webcamRef.current) {
      setImgSrc(webcamRef.current.getScreenshot());
    }
  }, [webcamRef]);

  const handleDelete = useCallback(() => {
    setImgSrc(null);
    input.onChange(null);
  }, [input]);

  const handleRetakeModal = useCallback(() => {
    if (imgSrc) {
      setOpen(true);
    }
    handleDelete();
  }, [imgSrc, handleDelete]);

  const handleContinue = useCallback(async () => {
    const files = [];
    if (imgSrc) {
      const file = await urlToFile(imgSrc, `${label}.jpg`, "image/jpeg");
      files.push(file);
    }
    input.onChange({ inputData: files });
    setOpen(false);
  }, [imgSrc, input, label]);

  return (
    <>
      <Field name={name} validate={validate}>
        {({ input, meta }: any) => (
          <FileBlock
            width={width}
            height={height}
            label={label}
            icon={icon}
            namePrefix={name}
            input={input}
            meta={meta}
            handleOpenModal={handleOpenModal}
            handleRetakeModal={handleRetakeModal}
            handleDelete={handleDelete}
          />
        )}
      </Field>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        sx={{
          "& .MuiBackdrop-root": {
            background: "#E3E3E3",
          },
          background: "#1C1F21",
        }}
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          <CloseBtnModal isLabel onClick={handleClose} />
          <ModalWrap
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <CameraWrapper isImg={!!imgSrc}>
              {imgSrc ? (
                <img src={imgSrc} alt='preview' />
              ) : (
                <Camera
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat='image/jpeg'
                />
              )}
            </CameraWrapper>
            <CameraPanel
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              gap='30px'
            >
              {imgSrc ? (
                <>
                  <ContinueBtn variant='contained' onClick={handleContinue}>
                    Continue
                  </ContinueBtn>
                  <RetakeBtn onClick={handleRetakeModal}>
                    Retake photo
                  </RetakeBtn>
                </>
              ) : (
                <>
                  <PanelLabel>Position the document in the frame</PanelLabel>
                  <PanelButton onClick={handleClick}>
                    <InnerPanelButton />
                  </PanelButton>
                </>
              )}
            </CameraPanel>
          </ModalWrap>
        </Box>
      </Modal>
    </>
  );
};
