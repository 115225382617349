import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useOnBoardingContext } from "../../../hooks/useOnBoardingContext";
import { getVerificationRequest } from "../../../network";
import { IframeContainer } from "./styles";

export const AmlStep = () => {
  const [refUrlId, setRefUrlId] = useState("");
  const [widgetHeight, setWidgetHeight] = useState<number | null>(null);
  const { requestId } = useOnBoardingContext();

  const MIN_HEIGHT = 550;

  useEffect(() => {
    localStorage.setItem("isAmlVisited", JSON.stringify(true));
    const fetch = async () => {
      if (requestId) {
        const currentRequest = await getVerificationRequest(requestId);
        setRefUrlId(currentRequest.refUrlId ?? "");
      }
    };
    fetch();
  }, [requestId]);

  useEffect(() => {
    setWidgetHeight(Math.max(window.innerHeight - 390, MIN_HEIGHT));
  }, []);

  return (
    <Stack spacing={4}>
      {refUrlId && widgetHeight && (
        <IframeContainer sx={{ height: widgetHeight }}>
          <iframe
            id='amliframe'
            src={`https://kyc-forms.amlbot.com/${refUrlId}`}
            allow='microphone *;camera *;midi *;encrypted-media *;'
            width='100%'
            height={widgetHeight + 75 + "px"}
          />
        </IframeContainer>
      )}
    </Stack>
  );
};
