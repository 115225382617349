import { useCallback, useState } from 'react';
import { TVerificationRequest } from '../models';
import { sendVerificationRequest } from '../network';

export const useVerificationRequestSend = (): [
  TVerificationRequest | null,
  boolean,
  Error | null,
  (id: string) => void
] => {
  const [result, setResult] = useState<TVerificationRequest | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async (id: string) => {
    if (!id) {
      setError(new Error('Send failed. id is not provided'));
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const result = await sendVerificationRequest(id);
      setResult(result);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error('Unknown error'));
      }
    }

    setIsLoading(false);
  }, []);

  return [result, isLoading, error, request];
};
