import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/CheckCircle';
import { WidgetLayout } from '../../../../components/WidgetLayout';

export const OnBoardingFormSuccessScreen = () => {
  const { t } = useTranslation('onBoarding');

  return (
    <WidgetLayout title={t('Success.title')} subtitle={t('Success.subtitle')}>
      <Box
        sx={{
          width: '100%',
          height: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '60px',
        }}
      >
        <CheckIcon color='success' fontSize="inherit" />
      </Box>
    </WidgetLayout>
  );
};
