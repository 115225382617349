import { FC } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { ban, checkmark, reflective } from '../../../../../assets/icons'

export const ImageBlock: FC<{
  isCheck?: boolean
  label: string
  image: string
  isReflective?: boolean
  isRotate?:boolean
}> = ({ isCheck, label, image, isReflective,isRotate }) => (
  <Stack
    flexDirection='row'
    alignItems='center'
    sx={{ width: '100%', paddingTop: '16px' }}
  >
    <Stack
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
      sx={{ marginRight: '14px' }}
    >
      <Box mr='16px'>
        <img src={isCheck ? checkmark : ban} alt='mark' />
      </Box>
      <Box sx={{ position: 'relative' }}>
        <img src={image} alt='image' style={{transform:isRotate?'rotate(-20.27deg) translate(5px,-20px)': 'none'}}/>
        {isReflective && (
          <img
            src={reflective}
            alt='reflective'
            style={{ position: 'absolute', top: '0px', left: '15px' }}
          />
        )}
      </Box>
    </Stack>
    <Stack justifyContent='center' alignItems='center' sx={{ width: '100%' }}>
      <Typography
        fontFamily='Red Hat Display'
        fontWeight='700'
        fontSize='14px'
        lineHeight='22px'
        textAlign='center'
        letterSpacing='0.5px'
        color={isCheck ? '#2968EF' : '#CF0000'}
      >
        {label}
      </Typography>
    </Stack>
  </Stack>
)
