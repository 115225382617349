import { CardContent, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { VerificationAppDocumentType } from "../../models";
import countries from "../../data/counties.json";

const countryData: { [key: string]: string } = Object.fromEntries(
  countries.map((x) => [x.code, x.name])
);

export type TProps = {
  lines: [string, any][];
  filledDocType?: VerificationAppDocumentType;
};

export const ReviewStepItemFields = (props: TProps) => {
  const { lines, filledDocType } = props;
  const { t, i18n } = useTranslation(`onBoarding`, {
    keyPrefix: `${filledDocType}.fields`,
  });

  const keys =
    filledDocType &&
    Object.keys(
      //@ts-ignore
      i18n.options.resources?.[i18n.language]?.onBoarding?.[filledDocType]
        ?.fields
    );

  const checkIsCountry = (title: string) => {
    return (
      title.includes("country") ||
      title.includes("nationality") ||
      title.includes("middle")
    );
  };

  return (
    <>
      <Divider
        sx={{
          margin: "0 16px",
        }}
      />
      <CardContent>
        <Stack spacing={1}>
          {lines.map((line, index) =>
            keys?.includes(line[0]) ? (
              <Stack
                key={index}
                direction='row'
                spacing={2}
                justifyContent='space-between'
              >
                {line[0] === "expected_volume_of_transactions" ? (
                  <Typography fontWeight='700'>
                    Planned Investment <br /> per Year (EUR)
                  </Typography>
                ) : (
                  <Typography fontWeight='700'>{t(line[0])}</Typography>
                )}
                <Typography
                  minWidth='40%'
                  maxWidth='60%'
                  textAlign='end'
                  sx={{ wordWrap: "break-word" }}
                >
                  {checkIsCountry(line[0]) ? countryData[line[1]] : line[1]}
                </Typography>
              </Stack>
            ) : null
          )}
        </Stack>
      </CardContent>
    </>
  );
};
