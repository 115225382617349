import { FC } from "react";
import { Field } from "react-final-form";
import { CameraSelfie } from "./CameraSelfie";
import { FrontCamera } from "../../../../assets/icons";

export const FFInputSelfie: FC<{
  name: string;
  label: string;
  icon?: string;
  width?: string;
  height?: string;
  validate?: (value?: string | undefined) => string | undefined;
  onBlockContinue?: (value: boolean) => void;
}> = ({
  name,
  label,
  icon = FrontCamera,
  width,
  height,
  validate,
  onBlockContinue,
}) => (
  <Field name={name} validate={validate}>
    {({ input, meta }: any) => (
      <CameraSelfie
        label={label}
        icon={icon}
        input={input}
        meta={meta}
        width={width}
        height={height}
        onBlockContinue={onBlockContinue}
      />
    )}
  </Field>
);
