import { useCallback } from "react";
import { FaceRecognition } from "../components/FaceRecognition";
import { toBase64 } from "../helpers";

export const SelfieCameraScreen = () => {
    const handleFinish = useCallback(async (files: File[]) => {
        const base64Files = await Promise.all(
            files.map(async (f) => {
                return await toBase64(f);
            })
        );
        //@ts-ignore
        if (typeof window?.ReactNativeWebView?.postMessage === "function") {
            //@ts-ignore
            window?.ReactNativeWebView?.postMessage(
                JSON.stringify(base64Files)
            );
        }
    }, []);

    return (
        <>
            <FaceRecognition onFinish={handleFinish} />
        </>
    );
};
