import { Box, styled } from '@mui/material'

export const RequirementsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  width: 100%;
  height: auto;
  max-width: 762px;
  background: #ffffff;
  box-shadow: 0px -14.7721px 40.3971px rgba(0, 0, 0, 0.0399022),
    0px -6.13556px 16.7789px rgba(0, 0, 0, 0.0297979),
    0px -2.21911px 6.06859px rgba(0, 0, 0, 0.021727);
  border-radius: 16px;
`
