import { FC, useEffect, useMemo } from "react";
import { Box, IconButton, Stack } from "@mui/material";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  FileLabelInput,
  FileSubLabelInput,
  ImageFile,
  FileBlockWrapper,
  ErrorFileLabel,
  FileLabelInputLine,
  FileLabelSubtitle,
  DialogCustomLabel,
} from "./styles";
import { UploadedDoc } from "../../../../assets/icons";
import { useFileLoading } from "../../hooks/useFileLoading";
import AddIcon from "@mui/icons-material/Add";
// import RemoveIcon from "@mui/icons-material/Remove";

type Props = {
  input: any;
  meta: any;
  namePrefix: string;
  label?: string;
  subtitle?: string;
  InputComponent?: any;
  currentFileType?: string;
  width?: string;
  height?: number;
  icon?: string;
  document?: any;
  multiple?: boolean;
  required?: boolean;
  isDeleteBan?: boolean;
  maxSize?: number;
  extensions?: string[];
  handleOpenModal?: (e: any) => void;
  handleCloseModal?: () => void;
  handleRetakeModal?: () => void;
  handleDelete?: () => void;
  isMaximumInputs?: boolean;
  handleAddInput?: (e: any) => void;
  handleDeleteInput?: (e: Event, cb?: () => void) => void;
};

export const FileBlock: FC<Props> = ({
  width,
  height,
  label,
  subtitle,
  icon,
  document: fileDoc,
  currentFileType,
  namePrefix,
  input,
  meta,
  InputComponent,
  multiple = false,
  handleOpenModal,
  handleCloseModal,
  handleRetakeModal,
  handleDelete,
  maxSize,
  extensions,
  required = false,
  isDeleteBan = false,
  isMaximumInputs = false,
  handleAddInput,
  handleDeleteInput,
}) => {
  const [onFileLoad, onDelete, error] = useFileLoading(
    input,
    handleDelete,
    maxSize,
    extensions
  );
  const previewImg = (input?.value && URL.createObjectURL(input.value)) || null;
  const isMobile = useMemo(() => window.innerWidth < 400, []);
  const trimFileName = (name: string | undefined) => {
    if (name === undefined) {
      return "";
    }
    if (name.length > (isMobile ? 18 : 24)) {
      return (
        name.slice(0, isMobile ? 6 : 9) +
        "..." +
        name.slice(name.length - (isMobile ? 6 : 9), name.length)
      );
    }
    if (name.length <= (isMobile ? 18 : 24)) {
      return name;
    }
    return "";
  };

  const link = fileDoc.dataLink;
  const isPdf =
    input?.value?.type === "application/pdf" || currentFileType === "PDF";
  const isUploaded = !!input?.value || link;
  const fileName: string = trimFileName(input?.value?.name);

  const status =
    isUploaded || link
      ? "success"
      : !!meta?.error && !!meta?.touched
      ? "error"
      : "pending";

  useEffect(() => {
    const inputOrderNumber = Number(namePrefix.split("_")[3]);
    if (
      !isUploaded &&
      namePrefix.includes("FINANCIAL_INFORMATION_DOCUMENT") &&
      inputOrderNumber > 1
    ) {
      const newInput = document.getElementById(namePrefix);
      newInput?.click();
    }
  }, [namePrefix, isUploaded]);

  return (
    <Box width='100%'>
      <FileBlockWrapper
        status={status}
        width={width}
        height={isMobile ? height && height + 10 : height}
        onClick={handleOpenModal}
        htmlFor={namePrefix}
      >
        {icon && (
          <ImageFile
            src={
              isUploaded && isPdf
                ? UploadedDoc
                : previewImg
                ? previewImg
                : link
                ? link
                : icon
            }
          />
        )}
        <Stack flexDirection='column' minWidth={0}>
          {label && (
            <FileLabelInput status={status}>
              {label
                .split(/\r?\n/)
                .slice(0, 3)
                .map((line, index) => (
                  <FileLabelInputLine key={index}>{line}</FileLabelInputLine>
                ))}
              {label.split(/\r?\n/).length > 3 && <>...</>}
            </FileLabelInput>
          )}
          {subtitle && <FileLabelSubtitle>{subtitle}</FileLabelSubtitle>}
          {(isUploaded || link) && (
            <FileSubLabelInput isActive={isUploaded || link}>
              Uploaded
              <span
                style={{
                  color: "#1C1F21",
                  marginLeft: "8px",
                  wordBreak: "break-word",
                }}
              >
                {fileName || currentFileType}
              </span>
            </FileSubLabelInput>
          )}
        </Stack>
        {InputComponent && (
          <InputComponent
            required={required}
            onChange={(e: any) => onFileLoad(e, handleCloseModal)}
            accept='.jpeg, .jpg, .png, .bmp, .pdf'
            name={`${namePrefix}.inputData`}
            id={namePrefix}
            style={{ zIndex: "-1", position: "absolute", opacity: "0" }}
            multiple={multiple}
          />
        )}
        {(isUploaded || previewImg || link) && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translate(0,-50%)",
              right: "4px",
            }}
          >
            {!InputComponent && (
              <IconButton
                sx={{ color: "#8D959F" }}
                size='small'
                onClick={handleRetakeModal}
              >
                <RefreshTwoToneIcon />
              </IconButton>
            )}
            {(isUploaded || previewImg) && !(isDeleteBan || link) && (
              <IconButton
                sx={{ color: "#8D959F" }}
                size='small'
                onClick={(e: any) => {
                  if (handleDeleteInput) handleDeleteInput(e);
                  onDelete(e, fileDoc);
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
            {(link || isDeleteBan) && (
              <DialogCustomLabel variant='icon' htmlFor={namePrefix}>
                <AutorenewIcon />
              </DialogCustomLabel>
            )}
          </Box>
        )}
        {isUploaded && !isMaximumInputs && handleAddInput ? (
          <AddIcon
            color='primary'
            onClick={handleAddInput}
            style={{
              position: "absolute",
              transform: isMobile ? "translateX(50%)" : "translateY(-50%)",
              top: isMobile ? 142 : "50%",
              right: isMobile ? "50%" : -35,
              fontSize: 22,
              border: "1px solid #1976d2",
              borderRadius: "50%",
            }}
          />
        ) : null}
        {/* {handleDeleteInput ? (
          <RemoveIcon
            color='primary'
            onClick={(e: any) =>
              handleDeleteInput(e, () => onDelete(e, fileDoc))
            }
            style={{
              position: "absolute",
              transform: "translateY(-50%)",
              top: "50%",
              right: handleAddInput ? -70 : -35,
              fontSize: 22,
              border: "1px solid #1976d2",
              borderRadius: "50%",
            }}
          />
        ) : null} */}
      </FileBlockWrapper>
      {/* {isUploaded && !isMaximumInputs && handleAddInput ? (
        <AddIcon
          color='primary'
          onClick={handleAddInput}
          style={{
            // position: "absolute",
            // transform: "translateY(-50%)",
            // top: "50%",
            // right: -35,
            fontSize: 22,
            border: "1px solid #1976d2",
            borderRadius: "50%",
          }}
        />
      ) : null} */}
      {((meta?.error && meta?.touched) || error) && (
        <ErrorFileLabel>{meta?.error || error}</ErrorFileLabel>
      )}
    </Box>
  );
};
