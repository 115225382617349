import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import { SigninScreen } from "../../screens/SigninScreen";
import { UnauthorizedContainer } from "../UnauthorizedContainer";
import { useAuth } from "../../auth/useAuth";
import { AuthorizedContainer } from "../AuthorizedContainer";
import {
  OnBoardingHomeScreen,
  OnBoardingFormReviewScreen,
  OnBoardingFormContainer,
  OnBoardingFormStepScreen,
  OnBoardingFormStepDocumentScreen,
} from "../../modules/onBoarding";
import { OnBoardingContainer } from "../../modules/onBoarding/containers/OnBoardingContainer";
import { OnBoardingFormSuccessScreen } from "../../modules/onBoarding/screens/OnBoardingFormSuccessScreen";
import {
  SelfieCameraDebugScreen,
  SelfieCameraScreen,
} from "../../modules/cameraRecognition/screens";
import { OnBoardingFormTermsScreen } from "../../modules/onBoarding/screens/OnBoardingFormTermsScreen";
import { AddEmailScreen } from "../../modules/onBoarding/screens/OnBoardingAddEmailScreen";

export const NavigationContainer: FC = () => {
  const { loading } = useAuth();

  if (loading) {
    return null;
  }

  return (
    <Routes>
      <Route path='/' element={<AuthorizedContainer />}>
        <Route path='/' element={<OnBoardingContainer />}>
          <Route path='/' element={<OnBoardingHomeScreen />} />
          <Route path='/add-email' element={<AddEmailScreen />} />
          <Route path='/onboarding-form' element={<OnBoardingFormContainer />}>
            <Route index element={<OnBoardingFormTermsScreen />} />
            <Route path='terms' element={<OnBoardingFormTermsScreen />} />
            <Route path='review' element={<OnBoardingFormReviewScreen />} />
            <Route path='success' element={<OnBoardingFormSuccessScreen />} />
            <Route path=':stepIndex' element={<OnBoardingFormStepScreen />} />
            <Route
              path=':stepIndex/:documentType'
              element={<OnBoardingFormStepDocumentScreen />}
            />
          </Route>
        </Route>
      </Route>
      <Route path='/auth' element={<UnauthorizedContainer />}>
        <Route index element={<SigninScreen />} />
      </Route>
      <Route path='/selfiecamera' element={<SelfieCameraScreen />} />
      <Route path='/selfiecamera-debug' element={<SelfieCameraDebugScreen />} />
    </Routes>
  );
};
