import { useCallback, useEffect } from "react";
import { TFormData } from "../../onBoarding";
import useLocalStorage from "./useLocalStorage";
import { STEPPER_COMPLETED } from "../../onBoarding/models";

export const useStepper = (stepIndex: number, formData?: TFormData) => {
  const [completed, setCompleted] = useLocalStorage(STEPPER_COMPLETED, {});

  useEffect(() => {
    if (formData) {
      const newCompleted = { ...completed };
      Object.keys(formData).forEach((step) => {
        newCompleted[Number(step)] = true;
        setCompleted(newCompleted);
      });
    }
  }, [formData]);

  const handleComplete = useCallback(() => {
    const newCompleted = { ...completed };
    newCompleted[Number(stepIndex)] = true;
    setCompleted(newCompleted);
  }, [stepIndex]);

  return [completed, handleComplete];
};
