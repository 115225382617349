import {
  IDCardIcon,
  SelfieIcon,
  LocationResidenceIcon,
  GovermentDocIcon,
  PersonalIcon,
} from '../../../components/MuiIcons'

export const documentsIcons: {
  [key: string]: any
} = {
  UserInformation: IDCardIcon,
  Selfie: SelfieIcon,
  Residence: LocationResidenceIcon,
  ProofOfResidence: GovermentDocIcon,
  PassportFile: PersonalIcon,
}

export const getDocumentIcon = (documentType: string, props: any = {}): JSX.Element => {
  if (documentType in documentsIcons) {
    const Icon = documentsIcons[documentType];
    return <Icon {...props}/>;
  }

  // #TODO: add default document icon
  return <IDCardIcon {...props}/>;
}