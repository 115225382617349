import { FC } from 'react';
import { Stack, Typography, Box, Button } from '@mui/material';
import {
  howScan1,
  howScan2,
  howScan3,
  howScan4,
} from '../../../../assets/icons';
import { useTranslation } from 'react-i18next';

const cards: {
  id: number;
  text: string;
  icon: string;
}[] = [
  {
    id: 1,
    text: 'Avoid wearing hats',
    icon: howScan1,
  },
  {
    id: 2,
    text: 'Avoid wearing glasses',
    icon: howScan2,
  },
  {
    id: 3,
    text: 'Avoid wearing glasses',
    icon: howScan3,
  },
  {
    id: 4,
    text: 'Use enough lighting',
    icon: howScan4,
  },
];

export const HowScan: FC<{ handleNextStep: (nextStep: 'camera') => void }> = ({
  handleNextStep,
}) => {
  const { t } = useTranslation();

  return (
    <Stack flexDirection='column' justifyContent='center' alignItems='center'>
      <Stack
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        maxWidth='432px'
        pt='64px'
      >
        <Typography
          fontWeight='800'
          fontSize='32px'
          lineHeight='40px'
          letterSpacing='0.5px'
          color='#1C1F21'
        >
          Scan of your face
        </Typography>
        <Typography
          mt={1.5}
          fontWeight='400'
          fontSize='16px'
          lineHeight='24px'
          letterSpacing='0.5px'
          textAlign='center'
          color='#1C1F21'
        >
          A short video turning your head in different directions helps us
          recognize your identity
        </Typography>
      </Stack>
      <Box
        mt={4}
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: '1fr 1fr',
          gap: '16px',
        }}
      >
        {cards.map(({ id, text, icon }) => (
          <Box
            key={id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingBottom: '16px',
              width: '208px',
              height: '149px',
              background: '#FFFFFF',
              borderRadius: '14px',
            }}
          >
            <img src={icon} alt={t(`${text}`)} />
            <Typography
              mt={1}
              fontWeight='400'
              fontSize='14px'
              lineHeight='22px'
              textAlign='center'
              color='#1C1F21'
            >
              {t(`${text}`)}
            </Typography>
          </Box>
        ))}
      </Box>
      <Button
        variant='contained'
        onClick={() => handleNextStep('camera')}
        sx={{ marginTop: '32px', width: '160px', height: '44px' }}
      >
        Continue
      </Button>
    </Stack>
  );
};
