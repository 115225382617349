import { Outlet } from 'react-router-dom';
import { config } from '../../../../config';
import { StatusView } from '../../../ui/components/StatusView';
import { useOnBoardingContext } from '../../hooks/useOnBoardingContext';
import { useOnBoardingFormContext } from '../../hooks/useOnBoardingFormContext';
import { useVerificationApp } from '../../hooks/useVerificationApp';
import { OnBoardingFormProvider } from './OnBoardingFormProvider';

export * from './OnBoardingFormProvider';

const Loader = () => {
  const { restoring } = useOnBoardingFormContext();
  if (restoring) {
    return <StatusView loading fullScreen />
  }
  return (
    <Outlet />
  )
}

export const OnBoardingFormContainer = () => {
  const { request, requestLoading, requestError } = useOnBoardingContext();
  const [app, appLoading, appError] = useVerificationApp(config.appPublicKey);

  const loading = requestLoading || appLoading;
  let error: Error | string | null = requestError || appError;
  if (loading || error || !app || !request) {
    if (!loading && !error && (!app || !request)) {
      error = 'No data';
    }
    return <StatusView fullScreen loading={loading} error={error}/>
  }

  return (
    <OnBoardingFormProvider
      app={app}
      request={request}
    >
      <Loader />
    </OnBoardingFormProvider>
  );
};
