import React, { FC } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { PreviewContainer } from "./styles";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PDFPreviewer: FC<{ path?: string }> = ({ path }) => {
  return (
    <PreviewContainer>
      <Document file={path}>
        <Page
          pageNumber={1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
    </PreviewContainer>
  );
};

export default PDFPreviewer;
