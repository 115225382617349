import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { HashRouter as Router } from 'react-router-dom';
import { AuthProvider } from '../../auth/AuthProvider';
import { mainTheme } from '../../theme/mainTheme';
import { RootLayout } from '../../components/RootLayout';
import { NavigationContainer } from '../NavigationContainer';

export const RootContainer = () => {
  return (
    <ThemeProvider theme={mainTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SnackbarProvider>
          <Router>
            <RootLayout>
              <AuthProvider>
                <NavigationContainer />
              </AuthProvider>
            </RootLayout>
          </Router>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
