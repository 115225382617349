import { Alert, Box, CircularProgress, SxProps } from "@mui/material"

export type TProps = {
  fullScreen?: boolean;
  margin?: boolean;
  loading?: boolean;
  error?: Error | string | null;
  sx?: SxProps;
}

export const StatusView = (props: TProps) => {
  const {loading, error, fullScreen, margin, sx = {}} = props;
  const style: SxProps = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  if (fullScreen) {
    style.position = 'absolute';
    style.top = 0;
    style.left = 0;
    style.bottom = 0;
    style.right = 0;
  } else if (margin) {
    style.mt = 4;
    style.mb = 4;
  }

  let errorMessage = error;
  if (error instanceof Error) {
    errorMessage = error.message;
  }

  return (
    <Box sx={{
      ...style,
      ...sx,
    }}>
      {loading && <CircularProgress />}
      {!loading && error && <Alert severity="error">{errorMessage}</Alert>}
    </Box>
  )
}