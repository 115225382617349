import { useCallback, useEffect, useState } from 'react';
import { getVerificationRequestQRCode } from '../network';

export const useGetQRCode = (
  verificationRequestId?: string
): [any | null, boolean, Error | null, (verificationRequestId?: string) => void] => {
  const [result, setResult] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async (newVerificationRequestId?: string) => {
    const id = verificationRequestId || newVerificationRequestId;
    if (!id) {
      setError(
        new Error('Configuration failed. verificationRequestId is not provided')
      );
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const qrCode = await getVerificationRequestQRCode(id);
      setResult(qrCode);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error('Unknown error'));
      }
    }

    setIsLoading(false);
  }, [verificationRequestId]);

  useEffect(() => {
    if (verificationRequestId) {
      request();
    }
  }, [verificationRequestId, request]);

  return [result, isLoading, error, request];
};
