import { FC } from 'react'
import { CloseButton, CloseIconWrap } from './styles'
import { Typography } from '@mui/material'

export const CloseBtnModal: FC<{
  onClick: () => void
  isLabel?: boolean
  top?: string
  right?: string
}> = ({ onClick, isLabel, top, right }) => {
  return (
    <CloseButton onClick={onClick} disableRipple top={top} right={right}>
      {isLabel && (
        <Typography
          fontWeight='400'
          fontSize='14px'
          lineHeight='22px'
          color='#1c1f21'
        >
          Close
        </Typography>
      )}
      <CloseIconWrap />
    </CloseButton>
  )
}
