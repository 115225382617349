import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CardId } from '../../../../../assets/icons';
import { required } from './../../../../../utils/validation';
import { InputCamera } from '../../../../ui/components/InputCamera';
import { useForm } from 'react-final-form';
import { useEffect } from 'react';

export const Passport = () => {
  const { t } = useTranslation('onBoarding', { keyPrefix: 'Passport.fields' });
  const form = useForm();
  useEffect(() => {
    form.change('data.country', '');
  }, [form]);

  return (
    <Stack spacing={4}>
      <Stack flexDirection='column' gap={2}>
        <InputCamera
          name='files.PASSPORT_FIRST_PAGE'
          label={t('front_photo')}
          icon={CardId}
          validate={required}
        />
      </Stack>
    </Stack>
  );
};
