import { UserInformation } from './UserInformation';
import { Residence } from './Residence';
import { DriverLicence } from './DriverLicence';
import { Passport } from './Passport';
import { Id } from './Id';
import { Selfie } from './Selfie';
import { FinancialInformation } from './FinancialInformation';
import { Occupation } from './Occupation';
import { BeneficialOwnership } from './BeneficialOwnership';
import { AmlStep } from './AmlStep';

export const docTypesComponents: any = {
  UserInformation,
  Residence,
  AmlStep,
  DriverLicence,
  Passport,
  Id,
  Selfie,
  FinancialInformation,
  Occupation,
  BeneficialOwnership,
};
