import { Button, Stack, Typography } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import { HelpCircleIcon } from '../../../../components/MuiIcons'
import { urlToFile } from '../../../../utils/urlToFile'
import {
  FaceRecognitionService,
  Status,
} from '../../services/FaceRecognitionService'
import { PositionIndicator } from '../PositionIndicator'
import {
  FaceCamera,
  IndicatorContainer,
  PositionWrapper,
  StatusMessage,
} from './styles'

const messages = {
  starting: 'Start web cam',
  loading: 'Loading',
  initializing: 'Initializing',
  failed: 'Web cam failed',
  paused: 'Paused',
  reseted: 'Put your face to circle',
  searchFace: 'Put your face to circle',
  startingFace: 'Put your face to circle',
  holdingFace: 'Keep your face and look at camera',
  trackFacePositions: 'Rotate your head',
  finished: 'Success',
}

export type Props = {
  debug?: boolean;
  onFinish?: (files: File[]) => void
  handleNextStep?: (nextStep: 'info') => void
}

export const FaceRecognition = (props: Props) => {
  const { debug = false, onFinish, handleNextStep } = props
  const [status, setStatus] = useState<Status | null>(null)
  const [positions, setPositions] = useState<string[]>([])
  const faceRecognitionServiceRef = useRef<FaceRecognitionService | null>(null)

  // // @ts-ignore
  // // #TODO: remove from production
  // const debug = window.debugFaceRecognition ? true : false

  const onConranerRef = useCallback(
    (element: HTMLDivElement) => {
      if (element !== null && faceRecognitionServiceRef.current === null) {
        const faceRecognition = new FaceRecognitionService({
          container: element,
          debug: debug || false,
        })
        faceRecognitionServiceRef.current = faceRecognition
        faceRecognition.on('status', (status: Status) => {
          setStatus(status)
          if (status === 'reseted') {
            setPositions([])
          }
        })
        faceRecognition.on('position', ({ position }: { position: string }) => {
          setPositions((state) => [...state, position])
        })
        faceRecognition.on('finish', async (screenshots: any) => {
          if (onFinish) {
            const files: File[] = []
            for (const position in screenshots) {
              const file = await urlToFile(
                screenshots[position],
                `${position}.jpg`,
                'image/jpeg'
              )
              files.push(file)
            }
            onFinish(files)
          }
          if (faceRecognitionServiceRef.current !== null) {
            faceRecognitionServiceRef.current.destroy()
          }
        })
        faceRecognition.start()
      }
    },
    [onFinish, debug]
  )

  useEffect(() => {
    return () => {
      if (faceRecognitionServiceRef.current !== null) {
        faceRecognitionServiceRef.current.destroy()
      }
    }
  }, [])

  return (
    <Stack
      flexDirection='column'
      alignItems='center'
      sx={{ width: '100%', height: '100vh' }}
    >
      <PositionWrapper>
        <IndicatorContainer>
          <PositionIndicator
            debug={debug}
            active={status === 'trackFacePositions'}
            positions={positions}
          />
        </IndicatorContainer>
        <FaceCamera debug={debug} ref={onConranerRef} />
      </PositionWrapper>
      <Stack
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        mt='48px'
      >
        <StatusMessage>{status && messages[status]}</StatusMessage>
        {handleNextStep && (
          <Button onClick={() => handleNextStep('info')}>
            <Typography
              mt={2}
              fontWeight='400'
              fontSize='16px'
              lineHeight='24px'
              color='#2968EF'
              textTransform='none'
            >
              How to scan your face
              <HelpCircleIcon
                sx={{
                  position: 'relative',
                  top: '7px',
                  left: '10px',
                  width: '20px',
                  height: '20px',
                }}
              />
            </Typography>
          </Button>
        )}
      </Stack>
    </Stack>
  )
}
