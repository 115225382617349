import { StepConnector, stepConnectorClasses, styled } from "@mui/material";

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 10px)",
    right: "calc(50% + 10px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#fff",
    borderTopWidth: 4,
    borderRadius: 1,
  },
}));

export const QontoStepIconRoot = styled("div")<{
  ownerState: { active?: boolean };
}>(({ theme, ownerState }) => {
  return {
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#fff",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: theme.palette.primary.main,
    }),
    "& .QontoStepIcon-completedIcon": {
      width: 20,
      height: 20,
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
    },
    "& .QontoStepIcon-active": {
      width: 20,
      height: 20,
      borderRadius: "50%",
      border: `5px solid #fff`,
      backgroundColor: theme.palette.primary.main,
    },
    "& .QontoStepIcon-circle": {
      width: 20,
      height: 20,
      borderRadius: "50%",
      border: `5px solid #fff`,
    },
  };
});
