import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomRadiosContainer } from './styles';
import { useForm } from 'react-final-form';
import { useEffect, useState } from 'react';
import { TextField } from 'mui-rff';

export const BeneficialOwnership = () => {
  const [checked, setChecked] = useState(false);
  const [isPep, setIsPep] = useState('false');
  const { t } = useTranslation('onBoarding', {
    keyPrefix: 'BeneficialOwnership',
  });
  const form = useForm();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    form.change('data.ownership_confirmation', event.target.checked);
  };

  const handleIsPepChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPep((event.target as HTMLInputElement).value);
    form.change(
      'data.is_pep',
      (event.target as HTMLInputElement).value === 'true'
    );
  };

  useEffect(() => {
    setChecked(form.getState().values?.data?.ownership_confirmation);
    setIsPep(form.getState().values?.data?.is_pep ? 'true' : 'false');
  }, [form]);

  return (
    <Stack spacing={4}>
      <FormControlLabel
        label={t('fields.checkbox')}
        control={
          <Checkbox checked={checked} required onChange={handleChange} />
        }
      />
      <Typography
        variant='body1'
        fontWeight='bold'
        textAlign='center'
        letterSpacing='0.5px'
        color='#1C1F21'
      >
        {t('politically_title')}
      </Typography>
      <CustomRadiosContainer>
        <RadioGroup value={isPep} onChange={handleIsPepChange}>
          <FormControlLabel
            value={true}
            control={<Radio />}
            label={t('fields.pep')}
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label={
              <span dangerouslySetInnerHTML={{ __html: t('fields.not_pep') }} />
            }
          />
        </RadioGroup>
      </CustomRadiosContainer>

      <Typography>{t('step_text')}</Typography>

      <Box sx={{ display: 'none' }}>
        <TextField name='data.ownership_confirmation' />
        <TextField name='data.is_pep' />
      </Box>
    </Stack>
  );
};
