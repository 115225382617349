import { useCallback } from "react";
import { FaceRecognition } from "../components/FaceRecognition";

export const SelfieCameraDebugScreen = () => {
    const handleFinish = useCallback(() => {}, []);

    return (
        <>
            <FaceRecognition onFinish={handleFinish} debug />
        </>
    );
};
