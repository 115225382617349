import { useCallback, useEffect, useState } from 'react';
import { config } from '../../../config';
import { TVerificationRequest } from '../models';
import {
  getOrCreateVerificationRequest,
  getVerificationRequest,
  saveVerificationSession,
} from '../network';

export const useVerificationRequest = (options: {
  id?: string | null;
  category?: string;
  appPublicKey?: string;
}): [TVerificationRequest | null, boolean, Error | null, () => void] => {
  const { id, category, appPublicKey } = options;
  const [result, setResult] = useState<TVerificationRequest | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async () => {
    if (!id && (!category || !appPublicKey)) {
      setError(
        new Error(
          'Configuration failed. Provide id or category and appPublicKey'
        )
      );
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      let verificationRequest: TVerificationRequest | null = null;
      if (id) {
        verificationRequest = await getVerificationRequest(id);
      } else if (category && appPublicKey) {
        verificationRequest = await getOrCreateVerificationRequest(
          category,
          appPublicKey
        );
        if (config.verificationToken) {
          try {
            saveVerificationSession(
              config.verificationToken,
              verificationRequest.id
            );
          } catch (e) {
            console.error('Save verification session ERROR: ', e);
          }
        }
      }

      if (!config.appPublicKey && verificationRequest?.appPublicKey) {
        config.appPublicKey = verificationRequest?.appPublicKey;
      }

      setResult(verificationRequest);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error('Unknown error'));
      }
    }

    setIsLoading(false);
  }, [id, category, appPublicKey]);

  useEffect(() => {
    request();
  }, [request]);

  return [result, isLoading, error, request];
};
